import { FileItemModel } from '../../../../../models/module/fields/main';

export interface DriveAddNewModel {
  modalType: 'rename' | 'newFile' | 'newFolder';
  parentId: number;
  name?: string;
  mimeType?: string;
}

export interface DriveAddNewOutput {
  files?: FileItemModel[];
  name?: string;
}
