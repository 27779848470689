export const DEBOUNCE_TIMES = {
  default: 500,
  short: 200,
} as const;

export const DATE_TIME_FORMATS = {
  date: 'YYYY-MM-DD',
  time: 'HH:mm:ss',
  dateTime: 'YYYY-MM-DD HH:mm',
} as const;

export const FILE_SIZE_CONVERT = {
  KB: 1024,
  MB: 1024 * 1024,
  GB: 1024 * 1024 * 1024,
} as const;

export const FILE_SIZES = {
  maxFileSize: 60 * 1024 * 1024, // 60MB
  chunkSize: 50 * 1024 * 1024, // 50MB
} as const;
