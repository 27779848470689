export interface RenameFileParams {
  id: number;
  parentId: number;
  name: string;
  isFolder?: boolean;
}

export interface CreateFolderParams {
  parentId: number;
  name: string;
  setOnContract?: boolean;
}

export interface EditFolderParams {
  id: number;
  name: string;
  parentId: number;
}

export interface GetUploadUrlParams {
  parentId: number;
  mimeType: string;
  isCreateVersion?: boolean;
}

export interface SaveFileInfoParams {
  mimeType: string;
  isCreateVersion?: boolean;
  fileId: string;
  totalBlocks: number;
  parentId: number;
  contractId?: number;
  roleId?: number;
}

export interface SaveFileInfoBody {
  filename: string;
  bytes: number;
}
