export type KeyValueType<K = string, V = string> = {
  key: K;
  value: V;
};

export interface KeyValueModel<TKey, TValue> {
  key: TKey;
  value?: TValue;
  order?: number;
  description?: string;
  name?: string;
}

export interface KeyValueUserModel<TKey> extends KeyValueModel<TKey, string> {
  userId: number;
  isUsed: boolean;
  isGroup: boolean;
  roleIds: number[];
}

export interface KeyValueFormulaLevelFieldModel {
  relationId: number;
  level: number;
}

export interface KeyValueFormulaFieldModel<TKey> extends KeyValueModel<TKey, string> {
  levels: KeyValueFormulaLevelFieldModel[];
  relations: string;
  level: number;
  moduleId: number;
  parentModuleTypeIds: number[];
  relationNames: string[];
}
