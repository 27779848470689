import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SystemFieldsEnum } from '../../../../models/module/fields/enums';
import { ScreenFieldModel } from '../../../../models/module/fields/main';
import { GridSearchEntityInstance, GridViewModel } from '../../../../models/module/grid';
import {
  DataCard,
  DataCardViewPart,
  DataCardViewPartValueType,
  DataCardViewStateValue,
} from '../../../../models/module/main';
import { PRESET_BG_COLOR, PRESET_COLOR } from '../../../../shared/consts';
import { HttpService } from '../../../../shared/services/http.service';
import { Currency, CurrencyFn } from '../../../../models/system/currency';

@Injectable()
export class DataCardsViewService {
  constructor(
    private httpService: HttpService,
    private translate: TranslateService,
  ) {}

  getScreenFields(screenType: number) {
    return this.httpService.post<GridViewModel<ScreenFieldModel[]>>(
      `_api/ScreenFields/Search?screenType=${screenType}`,
      {},
      { useLoader: false },
    );
  }

  getDataCard(fields: ScreenFieldModel[], row: GridSearchEntityInstance, currency: Currency | CurrencyFn): DataCard {
    const parts = (fields || []).map(field => {
      const part = new DataCardViewPart(field.id, field);
      part.value = this.getDataCardViewPartValue(field, row);
      part.currency = typeof currency == 'function' ? currency(row) : currency;
      return part;
    });
    return {
      parts: parts,
      entity_instance_id: row.entity_instance_id,
      is_draft: row.is_draft,
      full_doc_num: row.full_doc_num,
      contract_id: row.contract_id,
      contract_no: row.contract_no,
      closed_on: row.closed_on,
      screen_id: row.screen_id,
      is_closed_state: row.state_type == 'Closed',
    } as DataCard;
  }

  private getDataCardViewPartValue(field: ScreenFieldModel, row: GridSearchEntityInstance): DataCardViewPartValueType {
    if (field.id == SystemFieldsEnum.state_name) {
      if (row.entity_template_id) {
        return {
          name: this.translate.instant('defaultStatus.InApproval'),
          fontColor: PRESET_COLOR,
          bgColor: PRESET_BG_COLOR,
        } as DataCardViewStateValue;
      }

      return {
        name: row[SystemFieldsEnum[SystemFieldsEnum.state_name]],
        fontColor: row[SystemFieldsEnum[SystemFieldsEnum.state_text_color]],
        bgColor: row[SystemFieldsEnum[SystemFieldsEnum.state_color]],
      } as DataCardViewStateValue;
    }
    return row[field.systemFieldName] || row[field.id];
  }
}
