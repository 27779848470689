import { GridSearchEntityInstance } from "../../models/module/grid";

export const DemoCardsEntityInstances: GridSearchEntityInstance[] = [
  {
    "entity_instance_id": 65106,
    "doc_num_increment": null,
    "full_doc_num": "D65106",
    "is_draft": true,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 100181,
    "updated_on": "2024-02-07T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2024-02-07T09:47:25.917",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "project_manager": "Jane Doe",
    "initiator": "Jane Doe",
    "initiator_role": "CIPO",
    "updated_by": "Jane Doe",
    "assignee": null,
    "assigneerole": null,
    "date_submitted": null,
    "contract_module_type_id": -1,
    "daysopened": 0,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Draft",
    "state_color": "#963af2",
    "state_text_color": "#ffffff",
    "state_type": "Start",
    "current_state_id": null,
    "closed_on": null,
    "due_date": null,
    "entity_id": 21,
    "contract_id": 809,
    "cntrct_entity_instance_id": 65106,
    "is_closed_state": false,
    "contract_no": "CT 002",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": false,
    "needs_approval": false,
    "near_overdue": false,
    "overdue": false,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "71": null,
    "71_sort": null,
    "72": "Long Island",
    "72_sort": "Long Island",
    "73": null,
    "73_sort": null,
    "-19": null,
    "-19_sort": null,
    "-54": "41",
    "-55": "46"
  },
  {
    "entity_instance_id": 65105,
    "doc_num_increment": null,
    "full_doc_num": "D65089",
    "is_draft": true,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 100181,
    "updated_on": "2024-02-07T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2024-02-07T09:46:39.307",
    "initiator": "Jane Doe",
    "initiator_role": "CIPO",
    "updated_by": "Jane Doe",
    "assignee": null,
    "assigneerole": null,
    "date_submitted": null,
    "contract_module_type_id": -1,
    "daysopened": 0,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Draft",
    "state_color": "#963af2",
    "state_text_color": "#ffffff",
    "state_type": "Start",
    "current_state_id": null,
    "closed_on": null,
    "due_date": null,
    "entity_id": 21,
    "contract_id": 808,
    "cntrct_entity_instance_id": 65105,
    "is_closed_state": false,
    "contract_no": "CT 003",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": false,
    "needs_approval": false,
    "orig_contract_value": 75900,
    "adjusted_contract_total": 71300,
    "contingency": 22000,
    "remaining_contingency": 2000,
    "total_approved_pp_amount": 4200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "project_manager": "Andrews Hayman",
    "near_overdue": false,
    "overdue": false,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "71": null,
    "71_sort": null,
    "72": "Azure Cove",
    "72_sort": "Azure Cove",
    "73": "1",
    "73_sort": "1",
    "-19": null,
    "-19_sort": null,
    "-54": "11",
    "-55": "78"
  },
  {
    "entity_instance_id": 65333,
    "doc_num_increment": 776,
    "full_doc_num": "CT-776",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 100181,
    "updated_on": "2024-02-16T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2024-02-16T08:19:00.63",
    "initiator": "Jane Doe",
    "initiator_role": "CIPO",
    "updated_by": "Jane Doe",
    "assignee": "-",
    "assigneerole": "UA",
    "date_submitted": "2024-02-16T08:19:00.677",
    "contract_module_type_id": -1,
    "daysopened": 3,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Active",
    "state_color": "#28b293",
    "state_text_color": "#ffffff",
    "state_type": "Open",
    "current_state_id": 95,
    "closed_on": null,
    "due_date": "2024-02-16T00:00:00",
    "entity_id": 21,
    "contract_id": 816,
    "cntrct_entity_instance_id": 65333,
    "is_closed_state": false,
    "contract_no": "CT 004",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": true,
    "needs_approval": false,
    "orig_contract_value": 120450,
    "adjusted_contract_total": 90450,
    "contingency": 145000,
    "remaining_contingency": 13000,
    "total_approved_pp_amount": 21200,
    "original_contract_start_date": "2023-02-07T09:47:25.917",
    "original_contract_completion_date": "2029-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "project_manager": "Harry Daly",
    "near_overdue": false,
    "overdue": true,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "71": null,
    "71_sort": null,
    "72": "Albatross Haven",
    "72_sort": "Albatross Haven",
    "73": "1515",
    "73_sort": "1515",
    "-19": null,
    "-19_sort": null,
    "-54": "5",
    "-55": "8"
  },
  {
    "entity_instance_id": 65196,
    "doc_num_increment": 775,
    "full_doc_num": "CT-775",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 100181,
    "updated_on": "2024-02-12T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2024-02-12T13:44:48.32",
    "initiator": "Jane Doe",
    "initiator_role": "CIPO",
    "updated_by": "Jane Doe",
    "assignee": null,
    "assigneerole": null,
    "date_submitted": "2024-02-12T13:45:37.673",
    "contract_module_type_id": -1,
    "daysopened": 0,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Completed",
    "state_color": "#064a80",
    "state_text_color": "#ffffff",
    "state_type": "Approved",
    "current_state_id": 96,
    "closed_on": "2024-02-12T00:00:00",
    "due_date": "2024-02-12T00:00:00",
    "entity_id": 21,
    "contract_id": 814,
    "cntrct_entity_instance_id": 65196,
    "is_closed_state": false,
    "contract_no": "CT 005",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": false,
    "needs_approval": false,
    "orig_contract_value": 89340,
    "adjusted_contract_total": 99340,
    "contingency": 35000,
    "remaining_contingency": 2000,
    "total_approved_pp_amount": 4200,
    "original_contract_start_date": "2021-02-01T09:47:25.917",
    "original_contract_completion_date": "2023-02-09T09:47:25.917",
    "adjusted_completion_date": "2025-02-11T19:47:25.917",
    "project_manager": "Blair Verona",
    "near_overdue": false,
    "overdue": false,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 225,
    "screen_name": "Screen 1",
    "71": null,
    "71_sort": null,
    "72": "Apollo Bay",
    "72_sort": "Apollo Bay",
    "73": "21",
    "73_sort": "21",
    "-19": null,
    "-19_sort": null,
    "-54": "88",
    "-55": "77"
  },
  {
    "entity_instance_id": 65195,
    "doc_num_increment": 774,
    "full_doc_num": "CT-774",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 100181,
    "updated_on": "2024-02-12T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2024-02-12T13:39:00.57",
    "initiator": "Jane Doe",
    "initiator_role": "CIPO",
    "updated_by": "Jane Doe",
    "assignee": "-",
    "assigneerole": "UA",
    "date_submitted": "2024-02-12T13:39:00.6",
    "contract_module_type_id": -1,
    "daysopened": 7,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Active",
    "state_color": "#28b293",
    "state_text_color": "#ffffff",
    "state_type": "Open",
    "current_state_id": 95,
    "closed_on": null,
    "due_date": "2024-02-12T00:00:00",
    "entity_id": 21,
    "contract_id": 813,
    "cntrct_entity_instance_id": 65195,
    "is_closed_state": false,
    "contract_no": "CT 006",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": true,
    "needs_approval": false,
    "orig_contract_value": 12686,
    "adjusted_contract_total": 24880,
    "contingency": 25000,
    "remaining_contingency": 2000,
    "total_approved_pp_amount": 700,
    "original_contract_start_date": "2021-02-07T09:47:25.917",
    "original_contract_completion_date": "2027-02-07T09:47:25.917",
    "adjusted_completion_date": "2023-02-12T19:47:25.917",
    "project_manager": "Wong Mia",
    "near_overdue": false,
    "overdue": true,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 225,
    "screen_name": "Screen 1",
    "71": null,
    "71_sort": null,
    "72": "Asgard Sands",
    "72_sort": "Asgard Sands",
    "73": "43",
    "73_sort": "43",
    "-19": null,
    "-19_sort": null,
    "-54": "95",
    "-55": "4"
  },
  {
    "entity_instance_id": 65159,
    "doc_num_increment": 773,
    "full_doc_num": "CT-773",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 100181,
    "updated_on": "2024-02-13T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2024-02-09T13:53:42.61",
    "initiator": "Jane Doe",
    "initiator_role": "CIPO",
    "updated_by": "Jane Doe",
    "assignee": null,
    "assigneerole": null,
    "date_submitted": "2024-02-09T13:53:42.633",
    "contract_module_type_id": -1,
    "daysopened": 2,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Completed",
    "state_color": "#064a80",
    "state_text_color": "#ffffff",
    "state_type": "Approved",
    "current_state_id": 96,
    "closed_on": "2024-02-13T00:00:00",
    "due_date": "2024-02-09T00:00:00",
    "entity_id": 21,
    "contract_id": 812,
    "cntrct_entity_instance_id": 65159,
    "is_closed_state": false,
    "contract_no": "CT 008",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": false,
    "needs_approval": false,
    "orig_contract_value": 31786,
    "adjusted_contract_total": 31780,
    "contingency": 25000,
    "remaining_contingency": 1000,
    "total_approved_pp_amount": 400,
    "original_contract_start_date": "2023-02-07T09:47:25.917",
    "original_contract_completion_date": "2026-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-06-11T19:47:25.917",
    "project_manager": "Stone John",
    "near_overdue": false,
    "overdue": false,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "71": null,
    "71_sort": null,
    "72": "Azure Marina",
    "72_sort": "Azure Marina",
    "73": "1414",
    "73_sort": "1414",
    "-19": null,
    "-19_sort": null,
    "-54": "48",
    "-55": "73"
  },
  {
    "entity_instance_id": 65106,
    "doc_num_increment": null,
    "full_doc_num": "D65106",
    "is_draft": true,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 100181,
    "updated_on": "2024-02-07T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2024-02-07T09:47:25.917",
    "initiator": "Morgan Freeman",
    "initiator_role": "CIPO",
    "updated_by": "Morgan Freeman",
    "assignee": null,
    "assigneerole": null,
    "assigneeorganization": "CIPO Inc.",
    "date_submitted": null,
    "contract_module_type_id": -1,
    "daysopened": 0,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Draft",
    "state_color": "#963af2",
    "state_text_color": "#ffffff",
    "state_type": "Start",
    "current_state_id": null,
    "closed_on": null,
    "due_date": null,
    "entity_id": 21,
    "contract_id": 809,
    "cntrct_entity_instance_id": 65106,
    "is_closed_state": false,
    "contract_no": "2",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": false,
    "needs_approval": false,
    "project_manager": null,
    "near_overdue": false,
    "overdue": false,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": null,
    "-52_sort": null,
    "-42": "0.00",
    "-42_sort": "00000000000000000000000000000000000000000000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Lake Huron",
    "72_sort": "Lake Huron",
    "73": "2",
    "73_sort": "2",
    "-45": null,
    "-45_sort": null,
    "-44": null,
    "-44_sort": null,
    "-39": "0.00",
    "-39_sort": "00000000000000000000000000000000000000000000000.00",
    "-69": null,
    "-69_sort": null,
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 65105,
    "doc_num_increment": null,
    "full_doc_num": "D65089",
    "is_draft": true,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 100181,
    "updated_on": "2024-02-07T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2024-02-07T09:46:39.307",
    "initiator": "Morgan Freeman",
    "initiator_role": "CIPO",
    "updated_by": "Morgan Freeman",
    "assignee": null,
    "assigneerole": null,
    "assigneeorganization": "CIPO Inc.",
    "date_submitted": null,
    "contract_module_type_id": -1,
    "daysopened": 0,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Draft",
    "state_color": "#963af2",
    "state_text_color": "#ffffff",
    "state_type": "Start",
    "current_state_id": null,
    "closed_on": null,
    "due_date": null,
    "entity_id": 21,
    "contract_id": 808,
    "cntrct_entity_instance_id": 65105,
    "is_closed_state": false,
    "contract_no": "1",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": false,
    "needs_approval": false,
    "project_manager": null,
    "near_overdue": false,
    "overdue": false,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": null,
    "-52_sort": null,
    "-42": "0.00",
    "-42_sort": "00000000000000000000000000000000000000000000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Lake Michigan",
    "72_sort": "Lake Michigan",
    "73": "1",
    "73_sort": "1",
    "-45": null,
    "-45_sort": null,
    "-44": null,
    "-44_sort": null,
    "-39": "0.00",
    "-39_sort": "00000000000000000000000000000000000000000000000.00",
    "-69": null,
    "-69_sort": null,
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 65333,
    "doc_num_increment": 776,
    "full_doc_num": "CT-776",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 100181,
    "updated_on": "2024-02-16T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2024-02-16T08:19:00.63",
    "initiator": "Morgan Freeman",
    "initiator_role": "CIPO",
    "updated_by": "Morgan Freeman",
    "assignee": "-",
    "assigneerole": "UA",
    "assigneeorganization": "CIPO Inc.",
    "date_submitted": "2024-02-16T08:19:00.677",
    "contract_module_type_id": -1,
    "daysopened": 4,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Active",
    "state_color": "#28b293",
    "state_text_color": "#ffffff",
    "state_type": "Open",
    "current_state_id": 95,
    "closed_on": null,
    "due_date": "2024-02-16T00:00:00",
    "entity_id": 21,
    "contract_id": 816,
    "cntrct_entity_instance_id": 65333,
    "is_closed_state": false,
    "contract_no": "1515",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": true,
    "needs_approval": false,
    "project_manager": null,
    "near_overdue": false,
    "overdue": true,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": "2025-04-26T00:00:00",
    "-52_sort": "2025-04-26T00:00:00",
    "-42": "8000000.00",
    "-42_sort": "00000000000000000000000000000000000000008000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Lake Erie",
    "72_sort": "Lake Erie",
    "73": "1515",
    "73_sort": "1515",
    "-45": "2025-04-26T00:00:00",
    "-45_sort": "2025-04-26T00:00:00",
    "-44": "2023-02-16T00:00:00",
    "-44_sort": "2023-02-16T00:00:00",
    "-39": "8000000.00",
    "-39_sort": "00000000000000000000000000000000000000008000000.00",
    "-69": null,
    "-69_sort": null,
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 65196,
    "doc_num_increment": 775,
    "full_doc_num": "CT-775",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 100181,
    "updated_on": "2024-02-12T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2024-02-12T13:44:48.32",
    "initiator": "Morgan Freeman",
    "initiator_role": "CIPO",
    "updated_by": "Morgan Freeman",
    "assignee": null,
    "assigneerole": null,
    "assigneeorganization": "CIPO Inc.",
    "date_submitted": "2024-02-12T13:45:37.673",
    "contract_module_type_id": -1,
    "daysopened": 0,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Completed",
    "state_color": "#064a80",
    "state_text_color": "#ffffff",
    "state_type": "Approved",
    "current_state_id": 96,
    "closed_on": "2024-02-12T00:00:00",
    "due_date": "2024-02-12T00:00:00",
    "entity_id": 21,
    "contract_id": 814,
    "cntrct_entity_instance_id": 65196,
    "is_closed_state": false,
    "contract_no": "21",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": false,
    "needs_approval": false,
    "project_manager": null,
    "near_overdue": false,
    "overdue": false,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 225,
    "screen_name": "Screen 1",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": null,
    "-52_sort": null,
    "-42": "0.00",
    "-42_sort": "00000000000000000000000000000000000000000000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Red Lake",
    "72_sort": "Red Lake",
    "73": "21",
    "73_sort": "21",
    "-45": null,
    "-45_sort": null,
    "-44": null,
    "-44_sort": null,
    "-39": "0.00",
    "-39_sort": "00000000000000000000000000000000000000000000000.00",
    "-69": null,
    "-69_sort": null,
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 65195,
    "doc_num_increment": 774,
    "full_doc_num": "CT-774",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 100181,
    "updated_on": "2024-02-12T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2024-02-12T13:39:00.57",
    "initiator": "Morgan Freeman",
    "initiator_role": "CIPO",
    "updated_by": "Morgan Freeman",
    "assignee": "-",
    "assigneerole": "UA",
    "assigneeorganization": "CIPO Inc.",
    "date_submitted": "2024-02-12T13:39:00.6",
    "contract_module_type_id": -1,
    "daysopened": 8,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Active",
    "state_color": "#28b293",
    "state_text_color": "#ffffff",
    "state_type": "Open",
    "current_state_id": 95,
    "closed_on": null,
    "due_date": "2024-02-12T00:00:00",
    "entity_id": 21,
    "contract_id": 813,
    "cntrct_entity_instance_id": 65195,
    "is_closed_state": false,
    "contract_no": "43",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": true,
    "needs_approval": false,
    "project_manager": null,
    "near_overdue": false,
    "overdue": true,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 225,
    "screen_name": "Screen 1",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": "2024-02-12T00:00:00",
    "-52_sort": "2024-02-12T00:00:00",
    "-42": "0.00",
    "-42_sort": "00000000000000000000000000000000000000000000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "8",
    "-72_sort": "00000000000000000000000000000000000000000000000008",
    "71": null,
    "71_sort": null,
    "72": "Kentucky Lake",
    "72_sort": "Kentucky Lake",
    "73": "43",
    "73_sort": "43",
    "-45": "2024-02-12T00:00:00",
    "-45_sort": "2024-02-12T00:00:00",
    "-44": "2024-02-12T00:00:00",
    "-44_sort": "2024-02-12T00:00:00",
    "-39": "0.00",
    "-39_sort": "00000000000000000000000000000000000000000000000.00",
    "-69": null,
    "-69_sort": null,
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 65159,
    "doc_num_increment": 773,
    "full_doc_num": "CT-773",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 100181,
    "updated_on": "2024-02-13T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2024-02-09T13:53:42.61",
    "initiator": "Morgan Freeman",
    "initiator_role": "CIPO",
    "updated_by": "Morgan Freeman",
    "assignee": null,
    "assigneerole": null,
    "assigneeorganization": "CIPO Inc.",
    "date_submitted": "2024-02-09T13:53:42.633",
    "contract_module_type_id": -1,
    "daysopened": 2,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Completed",
    "state_color": "#064a80",
    "state_text_color": "#ffffff",
    "state_type": "Approved",
    "current_state_id": 96,
    "closed_on": "2024-02-13T00:00:00",
    "due_date": "2024-02-09T00:00:00",
    "entity_id": 21,
    "contract_id": 812,
    "cntrct_entity_instance_id": 65159,
    "is_closed_state": false,
    "contract_no": "1414",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": false,
    "needs_approval": false,
    "project_manager": "Samuel L. Jackson",
    "near_overdue": false,
    "overdue": false,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": "2024-11-05T00:00:00",
    "-52_sort": "2024-11-05T00:00:00",
    "-42": "500000.00",
    "-42_sort": "00000000000000000000000000000000000000000500000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Lake Marion",
    "72_sort": "Lake Marion",
    "73": "1414",
    "73_sort": "1414",
    "-45": "2024-11-05T00:00:00",
    "-45_sort": "2024-11-05T00:00:00",
    "-44": "2022-02-09T00:00:00",
    "-44_sort": "2022-02-09T00:00:00",
    "-39": "500000.00",
    "-39_sort": "00000000000000000000000000000000000000000500000.00",
    "-69": "Samuel L. Jackson",
    "-69_sort": "Samuel L. Jackson",
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 64614,
    "doc_num_increment": 772,
    "full_doc_num": "CT-772",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 85,
    "updated_on": "2024-02-06T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2024-02-06T00:50:22.04",
    "initiator": "Bruce Willis",
    "initiator_role": "CIPO",
    "updated_by": "Bruce Willis",
    "assignee": "-",
    "assigneerole": "UA",
    "assigneeorganization": "CIPO Cloud",
    "date_submitted": "2024-02-06T00:50:22.043",
    "contract_module_type_id": -1,
    "daysopened": 0,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Active",
    "state_color": "#28b293",
    "state_text_color": "#ffffff",
    "state_type": "Open",
    "current_state_id": 95,
    "closed_on": null,
    "due_date": "2024-02-06T00:00:00",
    "entity_id": 21,
    "contract_id": 807,
    "cntrct_entity_instance_id": 64614,
    "is_closed_state": false,
    "contract_no": "Test 1",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": true,
    "needs_approval": false,
    "project_manager": null,
    "near_overdue": false,
    "overdue": false,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": null,
    "-52_sort": null,
    "-42": "0.00",
    "-42_sort": "00000000000000000000000000000000000000000000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Test 1",
    "72_sort": "Test 1",
    "73": "Test 1",
    "73_sort": "Test 1",
    "-45": null,
    "-45_sort": null,
    "-44": null,
    "-44_sort": null,
    "-39": "0.00",
    "-39_sort": "00000000000000000000000000000000000000000000000.00",
    "-69": null,
    "-69_sort": null,
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 64611,
    "doc_num_increment": 771,
    "full_doc_num": "CT-771",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 100175,
    "updated_on": "2024-02-05T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2024-02-05T12:45:25.867",
    "initiator": "Robin Williams",
    "initiator_role": "CIPO",
    "updated_by": "Robin Williams",
    "assignee": null,
    "assigneerole": null,
    "assigneeorganization": "CIPO Admin",
    "date_submitted": "2024-02-05T12:45:25.87",
    "contract_module_type_id": -1,
    "daysopened": 0,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Closed",
    "state_color": "#bf5350",
    "state_text_color": "#ffffff",
    "state_type": "Closed",
    "current_state_id": 276,
    "closed_on": "2024-02-05T00:00:00",
    "due_date": "2024-02-05T00:00:00",
    "entity_id": 21,
    "contract_id": 806,
    "cntrct_entity_instance_id": 64611,
    "is_closed_state": true,
    "contract_no": "12/12",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": false,
    "needs_approval": false,
    "project_manager": null,
    "near_overdue": false,
    "overdue": false,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": "2024-07-27T00:00:00",
    "-52_sort": "2024-07-27T00:00:00",
    "-42": "0.00",
    "-42_sort": "00000000000000000000000000000000000000000000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Kuiu Island",
    "72_sort": "Kuiu Island",
    "73": "12/12",
    "73_sort": "12/12",
    "-45": "2024-07-27T00:00:00",
    "-45_sort": "2024-07-27T00:00:00",
    "-44": "2023-10-01T00:00:00",
    "-44_sort": "2023-10-01T00:00:00",
    "-39": "0.00",
    "-39_sort": "00000000000000000000000000000000000000000000000.00",
    "-69": null,
    "-69_sort": null,
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 64608,
    "doc_num_increment": 770,
    "full_doc_num": "CT-770",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 100175,
    "updated_on": "2024-02-19T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2024-02-05T12:01:00.493",
    "initiator": "Robin Williams",
    "initiator_role": "CIPO",
    "updated_by": "Robin Williams",
    "assignee": "-",
    "assigneerole": "UA",
    "assigneeorganization": "CIPO Admin",
    "date_submitted": "2024-02-05T12:01:00.497",
    "contract_module_type_id": -1,
    "daysopened": 11,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Active",
    "state_color": "#28b293",
    "state_text_color": "#ffffff",
    "state_type": "Open",
    "current_state_id": 95,
    "closed_on": null,
    "due_date": "2024-02-05T00:00:00",
    "entity_id": 21,
    "contract_id": 805,
    "cntrct_entity_instance_id": 64608,
    "is_closed_state": false,
    "contract_no": "11/11",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": true,
    "needs_approval": false,
    "project_manager": null,
    "near_overdue": false,
    "overdue": true,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": "2024-07-27T00:00:00",
    "-52_sort": "2024-07-27T00:00:00",
    "-42": "3500000.00",
    "-42_sort": "00000000000000000000000000000000000000003500000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Etolin Island",
    "72_sort": "Etolin Island",
    "73": "11/11",
    "73_sort": "11/11",
    "-45": "2024-07-27T00:00:00",
    "-45_sort": "2024-07-27T00:00:00",
    "-44": "2023-10-01T00:00:00",
    "-44_sort": "2023-10-01T00:00:00",
    "-39": "3500000.00",
    "-39_sort": "00000000000000000000000000000000000000003500000.00",
    "-69": null,
    "-69_sort": null,
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 64607,
    "doc_num_increment": 769,
    "full_doc_num": "CT-769",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 100175,
    "updated_on": "2024-02-05T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2024-02-05T11:58:47.07",
    "initiator": "Robin Williams",
    "initiator_role": "CIPO",
    "updated_by": "Robin Williams",
    "assignee": null,
    "assigneerole": null,
    "assigneeorganization": "CIPO Admin",
    "date_submitted": "2024-02-05T11:58:47.073",
    "contract_module_type_id": -1,
    "daysopened": 0,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Closed",
    "state_color": "#bf5350",
    "state_text_color": "#ffffff",
    "state_type": "Closed",
    "current_state_id": 276,
    "closed_on": "2024-02-05T00:00:00",
    "due_date": "2024-02-05T00:00:00",
    "entity_id": 21,
    "contract_id": 804,
    "cntrct_entity_instance_id": 64607,
    "is_closed_state": true,
    "contract_no": "10/10",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": false,
    "needs_approval": false,
    "project_manager": null,
    "near_overdue": false,
    "overdue": false,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": "2024-06-27T00:00:00",
    "-52_sort": "2024-06-27T00:00:00",
    "-42": "2500000.00",
    "-42_sort": "00000000000000000000000000000000000000002500000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Mitkof Island",
    "72_sort": "Mitkof Island",
    "73": "10/10",
    "73_sort": "10/10",
    "-45": "2024-06-27T00:00:00",
    "-45_sort": "2024-06-27T00:00:00",
    "-44": "2023-09-01T00:00:00",
    "-44_sort": "2023-09-01T00:00:00",
    "-39": "2500000.00",
    "-39_sort": "00000000000000000000000000000000000000002500000.00",
    "-69": null,
    "-69_sort": null,
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 64537,
    "doc_num_increment": 768,
    "full_doc_num": "CT-768",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 281,
    "updated_on": "2023-12-15T00:00:00",
    "created_by_role_id": null,
    "created_on": "2023-12-15T16:44:42.657",
    "initiator": "Jane Doe",
    "initiator_role": null,
    "updated_by": "Jane Doe",
    "assignee": "-",
    "assigneerole": "UA",
    "assigneeorganization": "CIPO Inc.",
    "date_submitted": "2023-12-15T16:49:08",
    "contract_module_type_id": -1,
    "daysopened": 67,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Active",
    "state_color": "#28b293",
    "state_text_color": "#ffffff",
    "state_type": "Open",
    "current_state_id": 95,
    "closed_on": null,
    "due_date": "2023-12-15T00:00:00",
    "entity_id": 21,
    "contract_id": 803,
    "cntrct_entity_instance_id": 64537,
    "is_closed_state": false,
    "contract_no": "76490-76492",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": true,
    "needs_approval": false,
    "project_manager": "Denzel Washington",
    "near_overdue": false,
    "overdue": true,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": null,
    "-52_sort": null,
    "-42": "0.00",
    "-42_sort": "00000000000000000000000000000000000000000000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Kanaga Island",
    "72_sort": "Kanaga Island",
    "73": "76490-76492",
    "73_sort": "76490-76492",
    "-45": null,
    "-45_sort": null,
    "-44": null,
    "-44_sort": null,
    "-39": "0.00",
    "-39_sort": "00000000000000000000000000000000000000000000000.00",
    "-69": "Denzel Washington",
    "-69_sort": "Denzel Washington",
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 63983,
    "doc_num_increment": 767,
    "full_doc_num": "CT-767",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 278,
    "updated_on": "2023-12-07T00:00:00",
    "created_by_role_id": 123,
    "created_on": "2023-12-07T21:33:43.81",
    "initiator": "Hugh Jackman",
    "initiator_role": "CAR",
    "updated_by": "Hugh Jackman",
    "assignee": "-",
    "assigneerole": "UA",
    "assigneeorganization": "CIPO Inc.",
    "date_submitted": "2023-12-07T21:33:43.813",
    "contract_module_type_id": -1,
    "daysopened": 75,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Active",
    "state_color": "#28b293",
    "state_text_color": "#ffffff",
    "state_type": "Open",
    "current_state_id": 95,
    "closed_on": null,
    "due_date": "2023-12-07T00:00:00",
    "entity_id": 21,
    "contract_id": 802,
    "cntrct_entity_instance_id": 63983,
    "is_closed_state": false,
    "contract_no": "77602",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": true,
    "needs_approval": false,
    "project_manager": null,
    "near_overdue": false,
    "overdue": true,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": null,
    "-52_sort": null,
    "-42": "0.00",
    "-42_sort": "00000000000000000000000000000000000000000000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Knight Island",
    "72_sort": "Knight Island",
    "73": "77602",
    "73_sort": "77602",
    "-45": null,
    "-45_sort": null,
    "-44": null,
    "-44_sort": null,
    "-39": "0.00",
    "-39_sort": "00000000000000000000000000000000000000000000000.00",
    "-69": null,
    "-69_sort": null,
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 63848,
    "doc_num_increment": 766,
    "full_doc_num": "CT-766",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 281,
    "updated_on": "2023-12-05T00:00:00",
    "created_by_role_id": null,
    "created_on": "2023-12-05T22:10:38.227",
    "initiator": "Jane Doe",
    "initiator_role": null,
    "updated_by": "Jane Doe",
    "assignee": "-",
    "assigneerole": "UA",
    "assigneeorganization": "CIPO Inc.",
    "date_submitted": "2023-12-05T22:15:35.3",
    "contract_module_type_id": -1,
    "daysopened": 77,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Active",
    "state_color": "#28b293",
    "state_text_color": "#ffffff",
    "state_type": "Open",
    "current_state_id": 95,
    "closed_on": null,
    "due_date": "2023-12-05T00:00:00",
    "entity_id": 21,
    "contract_id": 801,
    "cntrct_entity_instance_id": 63848,
    "is_closed_state": false,
    "contract_no": "74469-74471",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": true,
    "needs_approval": false,
    "project_manager": "Tom Hanks",
    "near_overdue": false,
    "overdue": true,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": null,
    "-52_sort": null,
    "-42": "0.00",
    "-42_sort": "00000000000000000000000000000000000000000000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Gravina Island",
    "72_sort": "Gravina Island",
    "73": "74469-74471",
    "73_sort": "74469-74471",
    "-45": null,
    "-45_sort": null,
    "-44": null,
    "-44_sort": null,
    "-39": "0.00",
    "-39_sort": "00000000000000000000000000000000000000000000000.00",
    "-69": "Tom Hanks",
    "-69_sort": "Tom Hanks",
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 63718,
    "doc_num_increment": 765,
    "full_doc_num": "CT-765",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 281,
    "updated_on": "2023-12-04T00:00:00",
    "created_by_role_id": null,
    "created_on": "2023-12-04T19:41:51.643",
    "initiator": "Jane Doe",
    "initiator_role": null,
    "updated_by": "Jane Doe",
    "assignee": "-",
    "assigneerole": "UA",
    "assigneeorganization": "CIPO Inc.",
    "date_submitted": "2023-12-04T19:46:24.47",
    "contract_module_type_id": -1,
    "daysopened": 78,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Active",
    "state_color": "#28b293",
    "state_text_color": "#ffffff",
    "state_type": "Open",
    "current_state_id": 95,
    "closed_on": null,
    "due_date": "2023-12-04T00:00:00",
    "entity_id": 21,
    "contract_id": 800,
    "cntrct_entity_instance_id": 63718,
    "is_closed_state": false,
    "contract_no": "71304, 71305",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": true,
    "needs_approval": false,
    "project_manager": "John Snow",
    "near_overdue": false,
    "overdue": true,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": null,
    "-52_sort": null,
    "-42": "0.00",
    "-42_sort": "00000000000000000000000000000000000000000000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Johns Island",
    "72_sort": "Johns Island",
    "73": "71304, 71305",
    "73_sort": "71304, 71305",
    "-45": null,
    "-45_sort": null,
    "-44": null,
    "-44_sort": null,
    "-39": "0.00",
    "-39_sort": "00000000000000000000000000000000000000000000000.00",
    "-69": "John Snow",
    "-69_sort": "John Snow",
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 63715,
    "doc_num_increment": 764,
    "full_doc_num": "CT-764",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 398,
    "updated_on": "2023-12-04T00:00:00",
    "created_by_role_id": null,
    "created_on": "2023-12-04T19:37:56.923",
    "initiator": "Tom Cruise",
    "initiator_role": null,
    "updated_by": "Tom Cruise",
    "assignee": "-",
    "assigneerole": "UA",
    "assigneeorganization": "CIPO Inc.",
    "date_submitted": "2023-12-04T19:39:30.07",
    "contract_module_type_id": -1,
    "daysopened": 78,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Active",
    "state_color": "#28b293",
    "state_text_color": "#ffffff",
    "state_type": "Open",
    "current_state_id": 95,
    "closed_on": null,
    "due_date": "2023-12-04T00:00:00",
    "entity_id": 21,
    "contract_id": 799,
    "cntrct_entity_instance_id": 63715,
    "is_closed_state": false,
    "contract_no": "271461, 272592, 16145",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": true,
    "needs_approval": false,
    "project_manager": "Leonardo DiCaprio",
    "near_overdue": false,
    "overdue": true,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": null,
    "-52_sort": null,
    "-42": "0.00",
    "-42_sort": "00000000000000000000000000000000000000000000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Yakobi Island",
    "72_sort": "Yakobi Island",
    "73": "271461, 272592, 16145",
    "73_sort": "271461, 272592, 16145",
    "-45": null,
    "-45_sort": null,
    "-44": null,
    "-44_sort": null,
    "-39": "0.00",
    "-39_sort": "00000000000000000000000000000000000000000000000.00",
    "-69": "Leonardo DiCaprio",
    "-69_sort": "Leonardo DiCaprio",
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 63704,
    "doc_num_increment": 763,
    "full_doc_num": "CT-763",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 398,
    "updated_on": "2023-12-04T00:00:00",
    "created_by_role_id": null,
    "created_on": "2023-12-04T17:47:58.24",
    "initiator": "Tom Cruise",
    "initiator_role": null,
    "updated_by": "Tom Cruise",
    "assignee": "-",
    "assigneerole": "UA",
    "assigneeorganization": "CIPO Inc.",
    "date_submitted": "2023-12-04T17:49:59.32",
    "contract_module_type_id": -1,
    "daysopened": 78,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Active",
    "state_color": "#28b293",
    "state_text_color": "#ffffff",
    "state_type": "Open",
    "current_state_id": 95,
    "closed_on": null,
    "due_date": "2023-12-04T00:00:00",
    "entity_id": 21,
    "contract_id": 798,
    "cntrct_entity_instance_id": 63704,
    "is_closed_state": false,
    "contract_no": "76487, 76488, 76489",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": true,
    "needs_approval": false,
    "project_manager": "Leonardo DiCaprio",
    "near_overdue": false,
    "overdue": true,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": null,
    "-52_sort": null,
    "-42": "0.00",
    "-42_sort": "00000000000000000000000000000000000000000000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Santa Catalina Island",
    "72_sort": "Santa Catalina Island",
    "73": "76487, 76488, 76489",
    "73_sort": "76487, 76488, 76489",
    "-45": null,
    "-45_sort": null,
    "-44": null,
    "-44_sort": null,
    "-39": "0.00",
    "-39_sort": "00000000000000000000000000000000000000000000000.00",
    "-69": "Leonardo DiCaprio",
    "-69_sort": "Leonardo DiCaprio",
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 63477,
    "doc_num_increment": 762,
    "full_doc_num": "CT-762",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 99,
    "updated_on": "2024-02-07T00:00:00",
    "created_by_role_id": -2,
    "created_on": "2023-11-29T18:57:42.093",
    "initiator": "Keanu Reeves",
    "initiator_role": "CIPO",
    "updated_by": "Betty White",
    "assignee": "-",
    "assigneerole": "UA",
    "assigneeorganization": "CIPO Inc.",
    "date_submitted": "2023-11-29T18:57:42.097",
    "contract_module_type_id": -1,
    "daysopened": 83,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Active",
    "state_color": "#28b293",
    "state_text_color": "#ffffff",
    "state_type": "Open",
    "current_state_id": 95,
    "closed_on": null,
    "due_date": "2023-11-29T00:00:00",
    "entity_id": 21,
    "contract_id": 797,
    "cntrct_entity_instance_id": 63477,
    "is_closed_state": false,
    "contract_no": "0000",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": true,
    "needs_approval": false,
    "project_manager": null,
    "near_overdue": false,
    "overdue": true,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": null,
    "-52_sort": null,
    "-42": "0.00",
    "-42_sort": "00000000000000000000000000000000000000000000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Hawkins Island",
    "72_sort": "Hawkins Island",
    "73": "0000",
    "73_sort": "0000",
    "-45": null,
    "-45_sort": null,
    "-44": null,
    "-44_sort": null,
    "-39": "0.00",
    "-39_sort": "00000000000000000000000000000000000000000000000.00",
    "-69": null,
    "-69_sort": null,
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 63245,
    "doc_num_increment": 761,
    "full_doc_num": "CT-761",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 398,
    "updated_on": "2023-11-27T00:00:00",
    "created_by_role_id": null,
    "created_on": "2023-11-27T20:34:35.757",
    "initiator": "Tom Cruise",
    "initiator_role": null,
    "updated_by": "Tom Cruise",
    "assignee": "-",
    "assigneerole": "UA",
    "assigneeorganization": "CIPO Inc.",
    "date_submitted": "2023-11-27T20:35:56.497",
    "contract_module_type_id": -1,
    "daysopened": 85,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Active",
    "state_color": "#28b293",
    "state_text_color": "#ffffff",
    "state_type": "Open",
    "current_state_id": 95,
    "closed_on": null,
    "due_date": "2023-11-27T00:00:00",
    "entity_id": 21,
    "contract_id": 796,
    "cntrct_entity_instance_id": 63245,
    "is_closed_state": false,
    "contract_no": "76430, 76431",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": true,
    "needs_approval": false,
    "project_manager": "Leonardo DiCaprio",
    "near_overdue": false,
    "overdue": true,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": null,
    "-52_sort": null,
    "-42": "0.00",
    "-42_sort": "00000000000000000000000000000000000000000000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Great Sitkin Island",
    "72_sort": "Great Sitkin Island",
    "73": "76430, 76431",
    "73_sort": "76430, 76431",
    "-45": null,
    "-45_sort": null,
    "-44": null,
    "-44_sort": null,
    "-39": "0.00",
    "-39_sort": "00000000000000000000000000000000000000000000000.00",
    "-69": "Leonardo DiCaprio",
    "-69_sort": "Leonardo DiCaprio",
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 63241,
    "doc_num_increment": 760,
    "full_doc_num": "CT-760",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 398,
    "updated_on": "2023-11-27T00:00:00",
    "created_by_role_id": null,
    "created_on": "2023-11-27T19:35:57.203",
    "initiator": "Tom Cruise",
    "initiator_role": null,
    "updated_by": "Tom Cruise",
    "assignee": "-",
    "assigneerole": "UA",
    "assigneeorganization": "CIPO Inc.",
    "date_submitted": "2023-11-27T19:37:57.537",
    "contract_module_type_id": -1,
    "daysopened": 85,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Active",
    "state_color": "#28b293",
    "state_text_color": "#ffffff",
    "state_type": "Open",
    "current_state_id": 95,
    "closed_on": null,
    "due_date": "2023-11-27T00:00:00",
    "entity_id": 21,
    "contract_id": 795,
    "cntrct_entity_instance_id": 63241,
    "is_closed_state": false,
    "contract_no": "268075",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": true,
    "needs_approval": false,
    "project_manager": "Leonardo DiCaprio",
    "near_overdue": false,
    "overdue": true,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": null,
    "-52_sort": null,
    "-42": "0.00",
    "-42_sort": "00000000000000000000000000000000000000000000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Duke Island",
    "72_sort": "Duke Island",
    "73": "268075",
    "73_sort": "268075",
    "-45": null,
    "-45_sort": null,
    "-44": null,
    "-44_sort": null,
    "-39": "0.00",
    "-39_sort": "00000000000000000000000000000000000000000000000.00",
    "-69": "Leonardo DiCaprio",
    "-69_sort": "Leonardo DiCaprio",
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  },
  {
    "entity_instance_id": 62997,
    "doc_num_increment": 759,
    "full_doc_num": "CT-759",
    "is_draft": false,
    "is_current": true,
    "revision": 0,
    "per_contract": false,
    "created_by": 278,
    "updated_on": "2023-11-22T00:00:00",
    "created_by_role_id": 123,
    "created_on": "2023-11-22T01:06:22.997",
    "initiator": "Hugh Jackman",
    "initiator_role": "CAR",
    "updated_by": "Hugh Jackman",
    "assignee": "-",
    "assigneerole": "UA",
    "assigneeorganization": "CIPO Inc.",
    "date_submitted": "2023-11-22T01:06:23.003",
    "contract_module_type_id": -1,
    "daysopened": 90,
    "ballincourt": null,
    "ballincourtrole": null,
    "state_name": "Active",
    "state_color": "#28b293",
    "state_text_color": "#ffffff",
    "state_type": "Open",
    "current_state_id": 95,
    "closed_on": null,
    "due_date": "2023-11-22T00:00:00",
    "entity_id": 21,
    "contract_id": 794,
    "cntrct_entity_instance_id": 62997,
    "is_closed_state": false,
    "contract_no": "77383",
    "module_id": -1,
    "needs_signed": false,
    "in_my_court": true,
    "needs_approval": false,
    "project_manager": null,
    "near_overdue": false,
    "overdue": true,
    "entity_template_id": null,
    "template_name": null,
    "template_description": null,
    "screen_id": 61,
    "screen_name": "CT Start Screen",
    "orig_contract_value": 34786,
    "adjusted_contract_total": 34780,
    "contingency": 45000,
    "remaining_contingency": 3000,
    "total_approved_pp_amount": 1200,
    "original_contract_start_date": "2024-02-07T09:47:25.917",
    "original_contract_completion_date": "2025-02-07T09:47:25.917",
    "adjusted_completion_date": "2024-02-12T19:47:25.917",
    "-54": "48",
    "-55": "73",
    "-52": null,
    "-52_sort": null,
    "-42": "0.00",
    "-42_sort": "00000000000000000000000000000000000000000000000.00",
    "-56": null,
    "-56_sort": null,
    "-72": "0",
    "-72_sort": "00000000000000000000000000000000000000000000000000",
    "71": null,
    "71_sort": null,
    "72": "Lake Superior",
    "72_sort": "Lake Superior",
    "73": "77383",
    "73_sort": "77383",
    "-45": null,
    "-45_sort": null,
    "-44": null,
    "-44_sort": null,
    "-39": "0.00",
    "-39_sort": "00000000000000000000000000000000000000000000000.00",
    "-69": null,
    "-69_sort": null,
    "-57": null,
    "-57_sort": null,
    "-19": null,
    "-19_sort": null,
    "-41": "0.00",
    "-41_sort": "00000000000000000000000000000000000000000000000.00",
    "-40": null,
    "-40_sort": null
  }
];