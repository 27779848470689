import { Component, ElementRef, input, signal, viewChild, inject } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CipoFormModule } from '../../../modules/forms.module';
import { CipoNumberConfig, CipoNumberControl } from '../common';
import { ThousandSeparatorDirective } from './thousand-separator.directive';
import { CipoTextViewComponent } from '../cipo-text-view/cipo-text-view.component';
import { UtilsService } from '../../../services';

@Component({
  selector: 'cipo-number',
  standalone: true,
  imports: [CipoFormModule, TranslateModule, ThousandSeparatorDirective, CipoTextViewComponent],
  templateUrl: './cipo-number.component.html',
})
export class CipoNumberComponent {
  utilsService = inject(UtilsService);
  fieldData = signal<CipoNumberConfig>({});
  canClearInput = signal(true);
  chipInput = viewChild<ElementRef<HTMLInputElement>>('chipInput');
  formatting = signal('1.0-0');
  selectedChipIndex: number;
  chipsField = new FormControl<string[]>([]);

  formControl = input<CipoNumberControl, CipoNumberControl>(null, {
    transform: control => {
      this.onFieldDataChange(control);
      return control;
    },
    alias: 'control',
  });
  /* this input is used to update required state of the chips field.
  May be also used to update single input required state (if needed) */
  required = input<boolean, boolean>(false, {
    transform: val => {
      if (this.fieldData()?.multiple) {
        this.chipsField.setValidators(this.formControl().validator);
        this.chipsField.updateValueAndValidity();
      }
      return val;
    },
  });

  readonly = input<boolean, boolean>(false, {
    transform: val => {
      this.fieldData.update(fieldData => ({ ...fieldData, readonly: val }));
      return val;
    },
  });

  onFieldDataChange(control: CipoNumberControl) {
    const fieldData = control.fieldData ?? {};

    this.fieldData.set(fieldData.isCurrency ? this.utilsService.formatCurrency(fieldData) : fieldData);
    const { readonly, suffixIcon, preventClearInput, decimals, multiple } = fieldData;
    multiple && this.chipsField.setValidators(control.validator);
    this.canClearInput.set(!readonly && !preventClearInput && !suffixIcon);

    if (decimals) {
      this.formatting.set(`1.${decimals}-${decimals}`);
    }
  }

  add(event: MatChipInputEvent) {
    const { chipInput, value } = event;
    if (!value) {
      return;
    }

    const formattedVal = this.utilsService.revertLocaleNumber(value);
    const val = (this.formControl().value || []) as number[];
    if (this.selectedChipIndex !== undefined) {
      val[this.selectedChipIndex] = formattedVal;
      this.selectedChipIndex = undefined;
    } else {
      val.push(formattedVal);
    }
    this.formControl().setValue(val);
    chipInput.clear();
  }
  remove(index: number) {
    const val = this.formControl().value as number[];
    val.splice(index, 1);
    this.formControl().setValue(val);
  }

  edit(index: number) {
    this.selectedChipIndex = index;
    this.chipInput().nativeElement.value = this.utilsService.formatLocaleNumber(this.formControl().value[index]);
    const values = this.formControl().value as number[];
    this.formControl().setValue(values);
    this.chipInput().nativeElement.focus();
  }
}
