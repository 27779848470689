import { Component, input, signal } from '@angular/core';
import { MatChipListboxChange } from '@angular/material/chips';
import { TranslateModule } from '@ngx-translate/core';

import { CipoListConfig, CipoListControl } from '../common';
import { CipoFormModule } from '../../../modules/forms.module';
import { CipoTextViewComponent } from '../cipo-text-view/cipo-text-view.component';

@Component({
  selector: 'cipo-listbox',
  templateUrl: './cipo-listbox.component.html',
  standalone: true,
  imports: [CipoFormModule, TranslateModule, CipoTextViewComponent],
})
export class CipoListboxComponent {
  fieldData = signal<CipoListConfig>({});

  formControl = input<CipoListControl, CipoListControl>(null, {
    transform: control => {
      this.onFormControlChange(control);
      return control;
    },
    alias: 'control',
  });

  onFormControlChange(control: CipoListControl) {
    this.fieldData.set(control.fieldData ?? {});
  }

  selectionChanged(event: MatChipListboxChange) {
    this.formControl().setValue(event.value);
    this.formControl().markAsTouched();
  }
}
