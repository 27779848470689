<div>
    @if (fieldData().readonly) {
        <cipo-text-view [value]="formControl().value" [config]="formControl().fieldData" [type]="fieldData().type" />
    } @else {
        <div fxLayout fxLayoutAlign="space-between center">
            @if (fieldData().label) {
                <label [ngClass]="formControl().touched && !formControl().valid ? 'cipo-text--warn-900' : ''">
                    {{ (fieldData().label | translate) + (fieldData().required ? "*" : "") }}
                </label>
            }
            @if (fieldData().fieldDescription) {
                <mat-icon
                    class="material-icons-outlined cursor--help"
                    [title]="fieldData().fieldDescription | translate">
                    info
                </mat-icon>
            }
        </div>
        <mat-chip-listbox
            class="mat-mdc-chip-set-stacked"
            (change)="selectionChanged($event)"
            fxLayout="row wrap"
            [multiple]="fieldData().multiple">
            @for (item of fieldData().options; track $index) {
                <mat-chip-option
                    [fxFlex.gt-sm]="100 / fieldData().columns - 2"
                    [fxFlex.lt-md]="100"
                    [value]="item.key"
                    [title]="item.value"
                    [selected]="formControl().value?.includes(item.key)"
                    togglePosition="before">
                    {{ item.value | translate }}
                </mat-chip-option>
            }
        </mat-chip-listbox>

        @if (formControl().touched && formControl().invalid) {
            <p class="hint m-l cipo-text--warn-900">
                {{ formControl().hasError("custom") ? formControl().errors.custom : ("errors.required" | translate) }}
            </p>
        } @else if (fieldData().hint) {
            <p class="hint m-l">{{ fieldData().hint | translate }}</p>
        }
    }
</div>
