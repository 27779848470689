import { cipo } from 'cipo';

cipo.factory("StateMod", function ($q, Model, URI, TransitionMod, Form, Message, Dictionaries, ModuleDictionaries, CLOSED_STATE_TYPE, $mdDialog) {
    var StateMod = Model.extend(function (obj, workflowId) {

        this.properties = {
            id: null,
            attr: "",
            approvalRoleIds: [],
            needsToApproveTemplateId: null,
            hasApprovedTemplateId: null,
            hasRejectedTemplateId: null,
            ballInCourtRoleIds: null,
            color: "#999",
            default: false,
            hasRevise: false,
            notifyDocumentInitiator: false,
            name: "",
            position: null,
            roleIds: [],
            signTransitions: [],
            signTransitionsIds: [],
            nonBindingSignTransitions: [],
            nonBindingSignTransitionsIds: [],
            templateNotificationId: null,
            textColor: "#fff",
            transitions: [],
            type: "",
            typeId: null
        }
        // this.moduleId = moduleId;

        var self = this;

        self.sources = ["allRoles", "stateTypes", "signatures", "notifications"];
        // self.transitionSources = ["states", "forms"];


        self.workflowId = workflowId;
        self.transitions = [];
        self.statesTypesDict = [];
        self.editMode = false;
        self.removedApproval = false;

        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key) && key != 'transitions' && key != 'ballInCourtRoleIds'
                    && key != 'nonBindingSignTransitions' && key != 'signTransitions') {
                    self.properties[key] = obj[key];
                } else if (key == 'transitions') {
                    self.transitions = [];
                    if (obj[key] && obj[key].length) {
                        for (var i = 0; i < obj[key].length; i++) {
                            var transition = new TransitionMod({
                                moduleId: ModuleDictionaries.moduleInfo.moduleId,
                                stateId: self.properties.id,
                                isStateDefault: self.properties.default,
                                stateType: self.properties.type,
                                workflowId: self.workflowId
                            }, obj[key][i]);

                            self.transitions.push(transition);
                        }
                    }

                } else if (key == 'ballInCourtRoleIds') {
                    self.properties[key] = obj[key] ? obj[key][0] : null;

                } else if (key == 'signTransitions' || key == 'nonBindingSignTransitions') {
                    for (var i = 0; i < obj[key].length; i++) {
                        self.properties[key + 'Ids'].push(obj[key][i].key);
                    }


                }
            }
            if (!obj.id && !obj.default) {
                if (self.properties.type == 2) {
                    self.properties.textColor = "#fff";
                    self.properties.color = "#0091ff";
                }
                if (self.properties.type == 101) {
                    self.properties.textColor = "#fff";
                    self.properties.color = "#064a80";
                }
                if (self.properties.type == 102) {
                    self.properties.textColor = "#fff";
                    self.properties.color = "#bf5350";
                }
                if (self.properties.type == 100) {
                    self.properties.textColor = "#fff";
                    self.properties.color = "#963AF2";
                }
            }

            if (obj.default) {
                self.get_draftDetails();
                self.isNoMove = true;

                if (ModuleDictionaries.moduleInfo.currentType) {
                    self.properties.moduleTypeId = ModuleDictionaries.moduleInfo.currentType.key;
                }
            }

        }

        if (!self.properties.id && !obj.default) self.createForm();

    });

    StateMod.prototype.get_draftDetails = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.STATES.DRAFT_DETAILS;

        self.isBusy = true;
        self[urlData.method](urlData, { url: { workflowId: self.workflowId }, urltype: "obj" }, { headers: { moduleId: ModuleDictionaries.moduleInfo.moduleId } })
            .then(function (r) {

                for (var key in r) {
                    if (r.hasOwnProperty(key)) {
                        self.properties[key] = r[key];
                    }
                }
                self.get_Transitions();
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
                Message.dberror(e);
            })
            .finally(function () {
                self.isBusy = false;
            })

        return p.promise;
    }



    StateMod.prototype.sync_Transitions = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.TRANSITION.SYNC_ORDER;
        var list = [];
        for (var i = 0; i < self.transitions.length; i++) {
            list.push({ key: self.transitions[i].properties.id, value: self.transitions[i].properties.name, order: i });
        }
        self.isBusy = true;
        self[urlData.method](urlData, { body: list }, { headers: { moduleId: ModuleDictionaries.moduleInfo.moduleId } })
            .then(function (result) {
                p.resolve();
                Message.info("Order updated.");
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
                Message.dberror('Order update failed');
            })
            .finally(function () {
                self.isBusy = false;
            })

        return p.promise;
    }

    StateMod.prototype.get_SignaturesDict = function () {
        var self = this;

        var p = $q.defer();
        if ([100, 101, 102].indexOf(parseInt(self.properties.type || 0)) != -1) {
            Dictionaries.Signatures({ moduleId: ModuleDictionaries.moduleInfo.moduleId }, { workflowId: self.workflowId })
                .then(function (r) {
                    self.signaturesDict = r;
                    ModuleDictionaries.dataSources.signatures.lookup = {};
                    for (var i = 0; i < r.length; i++) {
                        ModuleDictionaries.dataSources.signatures.lookup[r[i].key] = r[i];
                    }
                    p.resolve();
                })
                .catch(function (e) {
                    p.reject(e);
                    Message.dberror(e);
                    console.error(e);
                })
                .finally(function () {
                    self.isSignaturesDictLoaded = true;
                })
        } else p.resolve();

        return p.promise;
    }

    StateMod.prototype.getRoles = function () {
        return ModuleDictionaries.dataSources.allRoles.data;
    }

    StateMod.prototype.getUsedRoles = function () {
        return (this.getRoles() || []).filter(r => r.isUsed);
    }

    StateMod.prototype.createForm = function () {
        var self = this;
        self.form = new Form(self.properties);
        self.form.initializing = true;
        ModuleDictionaries.addUrlParameter({ workflowId: self.workflowId });
        ModuleDictionaries.getDicts(self.sources)
            .then(function () {

                self.mapStateTypes();

                if (self.properties.typeId == null && self.properties.type) self.properties.typeId = self.typesMapping[self.properties.type];

                var form = {
                    name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 255 } },
                    attr: { label: '', type: 'annotation', annotation: "<hr />" },
                    typeId: { label: 'Selected type', type: 'select', options: ModuleDictionaries.dataSources.stateTypes.data, validation: { required: true } },
                    color: { label: 'Background color', type: 'colorpicker', validation: { required: true } },
                    textColor: { label: 'Text color', type: 'colorpicker' },
                    ballInCourtRoleIds: {
                        label: 'Ball in court', type: 'select',
                        options: self.getUsedRoles()
                    },
                    signTransitionsIds: {
                        label: 'Signatures', type: 'select',
                        options: ModuleDictionaries.dataSources.signatures.data, multiple: true
                    },
                    nonBindingSignTransitionsIds: {
                        label: 'Signatures', type: 'select',
                        options: ModuleDictionaries.dataSources.signatures.data, multiple: true
                    }
                }

                var grid = [{ name: 30, typeId: 25, color: 22.5, textColor: 22.5 }];

                var useApprovalForm = [
                    { useApproval: 50, approvalRoleIds: 50 },
                    { submitLabel: 50, needsToApproveTemplateId: 50 },
                    { approveLabel: 50, hasApprovedTemplateId: 50 },
                    { rejectLabel: 50, hasRejectedTemplateId: 50 },
                    { useApprovalComment: 100 }
                ];
                var useApprovalCommentForm = [
                    { commentLabel: 50, commentTemplateId: 50 },
                ];

                var useBothApprovalsForm = [
                    { useApproval: 50, approvalRoleIds: 50 },
                    { submitLabel: 50, needsToApproveTemplateId: 50 },
                    { approveLabel: 50, hasApprovedTemplateId: 50 },
                    { rejectLabel: 50, hasRejectedTemplateId: 50 },
                    { useApprovalComment: 100 },
                    { commentLabel: 50, commentTemplateId: 50 },
                ];

                if (self.properties.default) {
                    // form.name.editMode = false;
                    form.typeId = { label: 'Type', type: 'select', options: [{ key: 0, value: 'Start' }] };
                    form.useApproval = { label: 'Documents need approval', type: 'checkbox' };
                    form.useApprovalComment = { label: 'Allow comments on documents awaiting approval', type: 'checkbox' };

                    var approvalForm = {
                        submitLabel: { label: 'Submit Button Label', type: 'text', validation: { requiredIfFieldHasValue: 'useApproval' } },
                        approveLabel: { label: 'Approve Button Label', type: 'text', validation: { requiredIfFieldHasValue: 'useApproval' }, },
                        rejectLabel: { label: 'Reject Button Label', type: 'text', validation: { requiredIfFieldHasValue: 'useApproval' } },
                        commentLabel: { label: 'Comment Button Label', type: 'text', validation: { requiredIfFieldHasValue: 'useApprovalComment' } },
                        approvalRoleIds: {
                            label: 'Roles to approve draft', type: 'select', multiple: true, validation: { requiredIfFieldHasValue: 'useApproval' },
                            options: self.getUsedRoles()
                        },
                        needsToApproveTemplateId: {
                            label: 'Draft Submitted Notification', type: 'select',
                            options: ModuleDictionaries.dataSources.notifications.data
                        },
                        hasApprovedTemplateId: { label: 'Approval Notification', type: 'select', options: ModuleDictionaries.dataSources.notifications.data },
                        hasRejectedTemplateId: { label: 'Rejection Notification', type: 'select', options: ModuleDictionaries.dataSources.notifications.data },
                        commentTemplateId: { label: 'Comment Notification', type: 'select', options: ModuleDictionaries.dataSources.notifications.data },
                    }

                    for (var key in approvalForm) if (approvalForm.hasOwnProperty(key)) form[key] = approvalForm[key];
                    grid = grid.concat([{ attr: 100 }, { useApproval: 100 }]);

                } else {
                    form.templateNotificationId = { label: 'Notification Template', type: 'select', options: ModuleDictionaries.dataSources.notifications.data };
                    form.roleIds = { label: 'Roles to notify', type: 'select', multiple: true, options: self.getUsedRoles() };
                    form.notifyDocumentInitiator = { label: 'Notify Document Initiator', type: 'checkbox' };
                    form.hasRevise = { label: 'Can start revision', type: 'checkbox' };

                    if (CLOSED_STATE_TYPE == self.properties.type)
                        grid.push({ templateNotificationId: 30, roleIds: 50, notifyDocumentInitiator: 20 });
                    else
                        grid.push({ templateNotificationId: 19, roleIds: 28, notifyDocumentInitiator: 18, ballInCourtRoleIds: 20, hasRevise: 15 });
                }

                form.typeId.editMode = false;
                self.form.set_Description(form);
                Object.defineProperty(self.form.fieldsList.ballInCourtRoleIds, 'editMode', {
                    get: function () { return self.properties.typeId == self.typesMapping[2] || self.properties.typeId == self.typesMapping[100] || self.properties.typeId == self.typesMapping[102] ? true : false; }
                });

                self.form.fieldsList.typeId.onClose = function (field) {
                    // console.error('changed', self, field);
                    if (self.properties.typeId != self.typesMapping[2] && self.properties.typeId != self.typesMapping[100] && self.properties.typeId != self.typesMapping[102]) self.properties.ballInCourtRoleIds = null;
                }

                if (self.properties.default) {

                    self.form.fieldsList.useApproval.onChange = function (field) {
                        var draftForm = angular.copy(grid);
                        if (self.properties.useApproval) {
                            draftForm.splice(-1);
                            if (!self.properties.useApprovalComment) {
                                draftForm = draftForm.concat(useApprovalForm);

                            } else {
                                draftForm = draftForm.concat(useBothApprovalsForm);
                            }

                            self.form.setTemplate('grid', draftForm);
                        } else if (field && self.properties.approvalsCount) {
                            var title = self.properties.approvalsCount * 1 > 1
                                ? `There are currently ${self.properties.approvalsCount} documents awaiting approval`
                                : 'There is currently one document awaiting approval';
                            var textContent = 'Removing the approval will automatically submit these documents. Proceed?';
                            var confirmDialog = $mdDialog.confirm()
                                .title(title)
                                .textContent(textContent)
                                .ariaLabel('Confirm Dialogue')
                                .ok('Yes')
                                .cancel('No')
                                .multiple(true);
                            $mdDialog.show(confirmDialog)
                                .then(function () {
                                    self.form.setTemplate('grid', draftForm);
                                    self.removedApproval = true;
                                })
                                .catch(function () {
                                    // console.log('No pressed');
                                    field._value = true;
                                });
                        }
                    }

                    self.form.fieldsList.useApprovalComment.onChange = function (field) {
                        var draftForm = angular.copy(grid);
                        if (self.properties.useApprovalComment) {
                            if (!self.properties.useApproval) {
                                draftForm = draftForm.concat(useApprovalCommentForm);
                            } else {
                                draftForm.splice(-1);
                                draftForm = draftForm.concat(useBothApprovalsForm);
                            }
                        } else {
                            if (self.properties.useApproval) {
                                draftForm.splice(-1);
                                draftForm = draftForm.concat(useApprovalForm);
                            }
                        }
                        self.form.setTemplate('grid', draftForm);

                    }
                }
                // 

                if (self.properties.typeId == self.typesMapping[101]
                    || self.properties.typeId == self.typesMapping[100]
                    || self.properties.typeId == self.typesMapping[102]) grid.push({
                        nonBindingSignTransitionsIds: 100
                    });
                self.form.setTemplate('grid', grid);
                if (self.properties.default) self.form.fieldsList.useApproval.onChange();
                self.form.store_Data();
                self.form.initializing = false;
            })
            .catch(function (e) { console.error(e); })

    }

    StateMod.prototype.mapStateTypes = function () {
        var self = this;
        self.typesMapping = {};
        if (ModuleDictionaries.dataSources.stateTypes.data.length) {
            for (var i = 0; i < ModuleDictionaries.dataSources.stateTypes.data.length; i++) {
                if (ModuleDictionaries.dataSources.stateTypes.data[i].key == self.properties.typeId) {
                    self.typeName = ModuleDictionaries.dataSources.stateTypes.data[i].value;
                }
                self.typesMapping[ModuleDictionaries.dataSources.stateTypes.data[i].type] = ModuleDictionaries.dataSources.stateTypes.data[i].key;
            }
        }
    }

    StateMod.prototype.setDicts = function (statesTypesDict, statesDict) {
        var self = this;
        // self.statesTypesDict = statesTypesDict;

        // if (self.properties.type) self.filterStatesDict(statesDict);

        // self.assignedRolesDict = assignedRolesDict;
        // self.signaturesDict = signaturesDict;
        // self.screensDict = screensDict;
        // self.notificationsDict = notificationsDict;
        //console.error('state', self);



    }

    StateMod.prototype.new_Transition = function (type) {
        var self = this;
        var gt = type ? type == 1 ? { globalType: 1, isGlobal: true } : { globalType: 2, isGlobal: true } : null;
        var transition = new TransitionMod({
            moduleId: ModuleDictionaries.moduleInfo.moduleId,
            stateId: self.properties.id,
            isStateDefault: self.properties.default,
            stateType: self.properties.type,
            workflowId: self.workflowId
        }, gt);

        return transition;
    }

    StateMod.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deleting = true;
        self.deletingMsg = "Deleting state...";
        var urlData = URI.STATES.DELETE;
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: ModuleDictionaries.moduleInfo.moduleId } })
            .then(function (result) {
                // console.log(result);
                Message.info('State deleted successfully');
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                self.deletingMsg = "The delete operation failed. Try again?";
                self.deleting = false;
                p.reject(e);
            })

        return p.promise;
    }

    StateMod.prototype.get_Transitions = function (isGT) {
        var self = this;
        var p = $q.defer();
        self.loadingTransitions = true;
        self.isTransitionsLoaded = false;
        self.isBusy = true;
        var urlData = self.properties.default ? URI.TRANSITION.DRAFT_LIST : URI.TRANSITION.LIST;
        var urlParams = self.properties.default ? { workflowId: self.workflowId } : { stateId: self.properties.id };
        // self.transitions = [];
        //self.properties.transitions = [];
        self[urlData.method](urlData, urlParams, { headers: { moduleId: ModuleDictionaries.moduleInfo.moduleId } })
            .then(function (result) {

                // console.log(result);
                self.loadingTransitions = false;
                self.transitions = [];
                if (result && result.length) {
                    for (var i = 0; i < result.length; i++) {
                        var transition = new TransitionMod({
                            moduleId: ModuleDictionaries.moduleInfo.moduleId,
                            stateId: self.properties.id,
                            isStateDefault: self.properties.default,
                            stateType: self.properties.type,
                            workflowId: self.workflowId
                        }, result[i]);


                        self.transitions.push(transition);
                    }
                }
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.isTransitionsLoaded = true;
                self.isBusy = false;
            })

        return p.promise;
    }

    StateMod.prototype.get_globalTransitions = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.TRANSITION.LIST_GLOBAL;
        self.isTransitionsLoaded = false;
        self[dataURL.method](dataURL, { url: { workflowId: self.workflowId }, urltype: "obj" }, { headers: { moduleId: ModuleDictionaries.moduleInfo.moduleId } })
            .then(function (r) {
                self.transitions = [];
                if (r && r.length) {
                    for (var i = 0; i < r.length; i++) {
                        var transition = new TransitionMod(
                            {
                                moduleId: ModuleDictionaries.moduleInfo.moduleId,
                                stateId: -1,
                                isStateDefault: false,
                                stateType: 103,
                                workflowId: 0
                            },
                            r[i]);

                        self.transitions.push(transition);
                    }
                }
                p.resolve(r);
            })
            .catch(function (e) {
                p.reject(e);
                Message.dberror(e);
                console.error(e);
            })
            .finally(function () {
                self.isTransitionsLoaded = true;
                self.isBusy = false;
            })
        return p.promise;
    }

    StateMod.prototype.transition_Action = function (item, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting transition..." : "Saving transition...";
        var action = isDelete ? item.delete() : item.save();
        //var isGT = self.properties.id == -1 ? true : false;
        action
            .then(function () {
                if (isDelete) {
                    self.transitions.splice(self.transitions.indexOf(item), 1);
                }
                if (self.properties.id == -1) self.get_globalTransitions();
                else {
                    if (!item.properties.id) {
                        delete item.form;
                        self.get_Transitions();
                    }
                }
                ModuleDictionaries.addUrlParameter({ workflowId: self.workflowId });
                ModuleDictionaries.refreshDicts(['signatures', 'signatureTransitions']);
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.message = "";
            })
        return p.promise;
    }

    StateMod.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;
        var urlData = self.properties.id ? URI.STATES.EDIT : URI.STATES.ADD;
        if (self.properties.default) urlData = URI.STATES.DRAFT_EDIT;
        self.form.validate();
        if (self.form.isValid) {
            var properties = angular.copy(self.properties);
            properties.ballInCourtRoleIds = !properties.ballInCourtRoleIds || properties.ballInCourtRoleIds == "" ? [] : [properties.ballInCourtRoleIds];
            properties.signTransitions = [];
            properties.nonBindingSignTransitions = [];
            if ((properties.signTransitionsIds || []).length) {
                for (var i = 0; i < properties.signTransitionsIds.length; i++) {
                    if (ModuleDictionaries.dataSources.signatures.lookup[properties.signTransitionsIds[i]])
                        properties.signTransitions.push(angular.copy(ModuleDictionaries.dataSources.signatures.lookup[properties.signTransitionsIds[i]]));

                }
            }
            if ((properties.nonBindingSignTransitionsIds || []).length) {
                for (var i = 0; i < properties.nonBindingSignTransitionsIds.length; i++) {
                    if (ModuleDictionaries.dataSources.signatures.lookup[properties.nonBindingSignTransitionsIds[i]])
                        properties.nonBindingSignTransitions.push(angular.copy(ModuleDictionaries.dataSources.signatures.lookup[properties.nonBindingSignTransitionsIds[i]]));

                }
            }
            //signTransitions: [],
            // signTransitionsIds: [],
            if (properties.transitions) delete properties.transitions;
            if (properties.signTransitionsIds) delete properties.signTransitionsIds;
            if (properties.nonBindingSignTransitionsIds) delete properties.nonBindingSignTransitionsIds;
            self[urlData.method](urlData, { url: { workflowId: self.workflowId }, urltype: "obj", body: properties }, { headers: { moduleId: ModuleDictionaries.moduleInfo.moduleId } })
                .then(function () {
                    Message.info('State saved successfully');
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    self.form.loading = false;
                    self.form.catch(e);
                    p.reject(e);
                })
                .finally(function () {

                });

        }
        else {
            self.form.loading = false;
            p.reject();
        }



        return p.promise;
    }

    return StateMod;
});
