export interface GridSearchEntityInstance {
    [key: string]: any;
    assignee?: string;
    assigneeorganization?: string;
    assigneerole?: string;
    ballincourt?: string;
    ballincourtrole?: string;
    closed_on?: string;
    cntrct_entity_instance_id?: number;
    contract_id?: number;
    contract_module_type_id?: number;
    contract_no?: string;
    created_by?: number;
    created_by_role_id?: number;
    created_on?: string;
    current_state_id?: number;
    date_submitted?: string;
    daysopened?: number;
    doc_num_increment?: number;
    due_date?: string;
    entity_id?: number;
    entity_instance_id?: number;
    entity_template_id?: number;
    full_doc_num?: string;
    in_my_court?: boolean;
    initiator?: string;
    initiator_role?: string;
    is_closed_state?: boolean;
    is_current?: boolean;
    is_draft?: boolean;
    module_id?: number;
    near_overdue?: boolean;
    needs_approval?: boolean;
    needs_signed?: boolean;
    overdue?: boolean;
    per_contract?: boolean;
    permission_list?: string;
    project_manager?: string;
    revision?: number;
    screen_id?: number;
    screen_name?: string;
    state_color?: string;
    state_name?: string;
    state_text_color?: string;
    state_type?: string;
    template_description?: string;
    template_name?: string;
    updated_by?: string;
    updated_on?: string;
}