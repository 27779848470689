export enum Permission {
    Create = 1,
    Read = 2,
    Update = 3,
    Delete = 4,
    Print = 5,
    Signatures = 6,
    Assignments = 7,
    ExcludeActivityItem = 8,
    ReleaseAdjustment = 9,
    PayReleasedAdjustment = 10,
    ChangeNumber = 11,
    CreateReports = 12,
    ReadReports = 13,
    DeleteReports = 14,
    UndoLastAction = 15,
    Archive = 16,
    CreateGlobal = 17,
    UpdateGlobal = 18,
    DeleteGlobal = 19,
    ManageDrafts = 20,
    ViewAccessLog = 21,
    CloseContract = 22,
}
