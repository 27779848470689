import { cipo } from 'cipo';
import { LegacyIcons } from 'src/app/legacy/constants';
import { environment } from 'src/environments/environment';

cipo.factory('userService', ['$http', '$q', 'localStorageService', 'URI', 'authService', 'Dictionaries',
    function ($http, $q, localStorageService, URI, authService, Dictionaries)
    {
        var userService = {};
        userService.redirectPage = null;

        userService.init = function ()
        {
            userService.tenantId = null;
            userService.hasTenantsLoaded = false;
            userService.isCurrentContractPeriodsChanged = false;
            userService.hasModulesLoaded = false;
            userService.isUserLoaded = false;
            userService.isAppBooted = false;
            userService.isReloadGrid = false;
            userService.isReloadModuleInformation = false;
            userService.targetState = null;
            userService.firstPage = 'dashboard';
            userService.contextIsChanged = false;
            userService.formats = {
                date: 'MM/DD/YYYY',
                datetime: 'MM/DD/YYYY hh:mm A',
                time: 'hh:mm A'
            };

            userService.system = {
                userdata: {
                    contractId: 0,
                    // True if user has any roles for correspondence with read permission
                    hasCorrespondenceAccess: false
                },
                context: {
                    program: undefined,
                    project: undefined,
                    contract: undefined,
                },
                contextTree: {
                    currencies: [],
                    modules: [],
                    programs: [],
                    loaded: false,
                },
                contextUseOnlyContract: true,
                contextRefresh: false,
                userRoleNames: [],
                userRoleAbbrevs: [],
                impList: [],
                tenants: [],
                tenantsLookup: {},
                contracts: [],
                menu: [],
                modules: {},
                modulesList: [],
                permissions: {},
                mNames: {},
                emailCounts: 0,
                icons: [],
                mimeTypes: {dict: {}, list: []},
                selectedModuleId: null,
                workflowId: null,
                oldWorkflowId: null
            };

        };

        userService.init();

        // clear
        userService.clearData = function ()
        {
            userService.init();
        };

        userService.get_EmailCounts = function () {
            var p = $q.defer();
            $http[URI.EMAIL.UNREAD_THREAD_COUNT.method](`${URI.EMAIL.UNREAD_THREAD_COUNT}?contractId=${userService.system.userdata.contractId}`.toString())
                .then(function (r) {
                    userService.system.emailCounts = r.data;
                    p.resolve(r);
                })
                .catch(function (e) { console.error(e); p.reject(); });

            return p.promise;
        }

        userService.Icons = function ()
        {
            var p = $q.defer();
            var icons = new LegacyIcons();

            $http[URI.ICONS.DICT.method](URI.ICONS.DICT.toString())
                .then(function (r)
                {
                    if (r && r.data.length)
                    {
                        for (var i = 0; i < r.data.length; i++)
                        {
                            icons.byKey.get(r.data[i].key).isFavorite = r.data[i].isFavorite;
                            icons.byKey.get(r.data[i].key).value = r.data[i].value;
                        }
                    }
                    userService.system.icons = icons;
                    p.resolve(icons);
                })
                .catch(function (e)
                {
                    p.reject(e);
                });

            return p.promise;
        };

        userService.mimeTypes = function ()
        {
            var p = $q.defer();
            var mimetypes = { dict: {}, list: [] };
            $http[URI.FIELD_DEFINITION.MIME_TYPES_DICT.method](URI.FIELD_DEFINITION.MIME_TYPES_DICT.toString())
                .then(function (r)
                {
                    if (r && r.data.length)
                    {
                        for (var i = 0; i < r.data.length; i++)
                        {
                            mimetypes.list.push(r.data[i]);
                            mimetypes.dict[r.data[i].key] = r.data[i];
                        }
                    }
                    userService.system.mimeTypes = mimetypes;
                    p.resolve();
                })
                .catch(function (e)
                {
                    console.error(e);
                    p.reject(e);
                    // Message.dberror(e);
                });

            return p.promise;
        };

        userService.getRolesForContract = function (contractId)
        {
            var p = $q.defer();

            userService.system.userRoleNames = [];
            userService.system.userRoleAbbrevs = [];

            if (contractId)
                $http.get(environment.baseApiUrl + '_api/Main/UserRolesForContract?contractId=' + contractId)
                    .then(function (r)
                    {
                        if (r && r.data && r.data.length)
                        {
                            var userRoleNames = [], userRoleAbbrevs = [];

                            for (var i = 0; i < r.data.length; i++)
                            {
                                userRoleNames.push(r.data[i].roleName);
                                userRoleAbbrevs.push(r.data[i].abbr);
                            }

                            userService.system.userRoleNames = userRoleNames;
                            userService.system.userRoleAbbrevs = userRoleAbbrevs;
                        }

                        p.resolve(r);
                    })
                    .catch(function (e) { console.error(e); p.reject(); });

            return p.promise;
        };

        userService.User = function ()
        {
            var p = $q.defer();

            $q.all([
                $http.get(environment.baseApiUrl + '_api/Main/UserData'), 
                Dictionaries.TenantSettings()
            ]).then(function (r) {
                if (window.StonlyWidget) {
                    window.StonlyWidget('identify', r[0].data.id.toString());
                }

                // To be removed after adding to store
                localStorage.setItem('INFINITE_SCROLL', r[0].data.loadMore ? '1' : '0');

                userService.system.userdata.signature = null;
                var lastContract = localStorageService.get('lastContract') || '0';
                if (!lastContract || lastContract === '0') {
                    var contracts = userService.getContextContracts() || [];
                    if (contracts.length) {
                        lastContract = contracts[0].id;
                        userService.system.lastContractContext = { contract: contracts[0] };
                        localStorageService.set('lastContract', lastContract);
                    }
                }

                for (var key in r[0].data)
                {
                    if (key == 'context')
                    {
                        userService.system.context = r[0].data[key];
                        userService.system.userdata.contractId = r[0].data.context.contract?.id ?? lastContract;

                        if (userService.system.context.contract) {
                            userService.system.lastContractContext = {...userService.system.context};
                        }
                    }
                    else
                    {
                        userService.system.userdata[key] = r[0].data[key];
                    }
                }

                userService.system.isMenuFolderAutoCollapse = (r[1] || []).some(s => s.name == 'menu_folder_auto_collapse' && s.value == '1');
                
                localStorageService.set('currentContract', userService.system.userdata.contractId);
                userService.isUserLoaded = true;
                userService.Icons();

                p.resolve(r[0]);
            })
            .catch(function (e)
            {
                console.error(e); 
                p.reject();
            });

            return p.promise;
        };
        
        userService.Tenants = function (tenantIdentifier)
        {
            var p = $q.defer();

            tenantIdentifier = tenantIdentifier || null;

            $http.get(environment.baseApiUrl + '_api/Main/Tenants')
                .then(function (r)
                {
                    userService.hasTenantsLoaded = true;
                    userService.system.tenants = r.data;
                    userService.setTenant(tenantIdentifier);

                    if (r.data && r.data.length)
                    {
                        for (var i = 0; i < r.data.length; i++)
                        {
                            userService.system.tenantsLookup[r.data[i].id] = r.data[i];
                        }
                    }

                    p.resolve(r);
                })
                .catch(function (e)
                {
                    if (e.status === 401)
                    {
                        authService.logout();
                    }

                    p.reject(e);
                });

            return p.promise;
        };

        userService.Reload_Tenant_Logos = function ()
        {
            var p = $q.defer();

            $http.get(environment.baseApiUrl + '_api/Main/Tenants')
                .then(function (r)
                {
                    if (r.data && r.data.length)
                    {
                        for (var i = 0; i < r.data.length; i++)
                        {
                            userService.system.tenantsLookup[r.data[i].id].logo = r.data[i].logo;
                            userService.system.tenantsLookup[r.data[i].id].bigLogo = r.data[i].bigLogo;
                        }
                    }
                    p.resolve(r);
                })
                .catch(function (e) { console.error(e); p.reject(); });

            return p.promise;
        };

        userService.closeContract = function () {
            userService.system.context = {
                ...userService.system.context, 
                contract: {
                    ...userService.system.context.contract, 
                    isClosed: true
                }
            };
            userService.contextIsChanged = true;
        }

        userService.Modules = function (contractId)
        {
            var p = $q.defer();
            contractId = contractId || 0;
            userService.isLoadingMenu = true;
            userService.hasModulesLoaded = false; 
            $http.get(environment.baseApiUrl + '_api/Menu/UserMenu?contractId=' + contractId)
                .then(function (r)
                {
                    userService.system.menu = r.data;
                    userService.system.modules = userService.parseModulesList(r.data);
                    userService.parseModuleNames();
                    userService.get_EmailCounts();
                    p.resolve(r);
                })
                .catch(function (e) { console.error(e); p.reject(); })
                .finally(function () { 
                    userService.isLoadingMenu = false; 
                    userService.hasModulesLoaded = true; 
                });

            return p.promise;
        };

        userService.parseModuleNames = function ()
        {
            var names = {};

            for (var key in userService.system.modules)
            {
                if (userService.system.modules.hasOwnProperty(key))
                {
                    names[key] = userService.system.modules[key].name;
                }
            }

            userService.system.mNames = names;
        };

        userService.bfs = function (tree, childrenKey, idKey, result)
        {
            if (!tree[childrenKey] || tree[childrenKey].length === 0)
            {
                return;
            }

            for (var i = 0; i < tree[childrenKey].length; i++)
            {
                var child = { roles: [], ...tree[childrenKey][i]};

                    if (child['typeId'] === 2 || child['typeId'] === 3)
                    {
                        result[child[idKey]] = child;
                        userService.system.modulesList.push(child);
                    }

                userService.bfs(child, childrenKey, idKey, result);
            }

            return;
        };

        userService.parseModulesList = function (modules)
        {
            var modulesList = {},
                modules = modules || [],
                dataTree = { 'children': modules };
            userService.bfs(dataTree, 'children', 'code', modulesList);
            
            return modulesList;
        };

        userService.getOperationsFor = function (code)
        {
            code = code || '_no_code';
            var operations = {};

            if (userService.system.modules[code])
            {
                if (userService.system.modules[code].operations)
                {
                    for (var i = 0; i < userService.system.modules[code].operations.length; i++)
                    {
                        operations[userService.system.modules[code].operations[i].name] = true;
                    }
                }
            }

            return operations;
        };

        userService.getOperationsForModule = function (moduleId)
        {
            var operations = {};

            var module = userService.system.modulesList.find(m => m.moduleId == moduleId);
            if (module) {
                return userService.getOperationsFor(module.code);
            }

            return operations;
        };

        userService.getWorkflow = function (moduleId) {            
            var p = $q.defer();
            $http[URI.MODULE.GET_WORKFLOW.method](`${URI.MODULE.GET_WORKFLOW}?entityInstanceId=${userService.system.userdata.contractEntityInstanceId || 0}&moduleId=${moduleId}`.toString())
                .then(function (r) {
                    userService.system.oldWorkflowId = userService.system.workflowId;
                    userService.system.workflowId = r.data;
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                });

            return p.promise;
        }

        userService.getModuleIdentifierById = function (moduleId)
        {
            moduleId = moduleId || 0;
            var retval = null;

            for (var key in userService.system.modules)
            {
                if (userService.system.modules[key]['moduleId'] == moduleId)
                {
                    retval = userService.system.modules[key]['code'];
                    break;
                }
            }

            return retval;
        };

        userService.getModuleObjectById = function (moduleId) {
            moduleId = moduleId || 0;
            var retval = null;

            for (var key in userService.system.modules) {
                if (userService.system.modules[key]['moduleId'] == moduleId) {
                    retval = userService.system.modules[key];
                }
            }

            return retval;
        };

        userService.getPermissions = function (code)
        {
            code = code || 'no_code';

            var permissions = {};

            if (userService.system.modules[code])
            {
                for (var i = 0; i <= userService.system.modules[code].operations; i++)
                {
                    permissions[userService.system.modules[code].operations[i].Name] = true;
                }
            }

            return permissions;
        };

        userService.getPermissionsIdList = function (code) {
            code = code || 'no_code';
            if (userService.system.modules[code])
            {
                var permissions = (userService.system.modules[code].operations || []).map(o => o.id);
                return permissions;
            }
            return [];
        };

        userService.currentTenant = function () {
            var sTenant = localStorageService.get('CurrentTenant');
            return userService.tenantId !== null
                ? userService.tenantId 
                : typeof sTenant !== 'undefined'
                    ? sTenant : null;
        };

        userService.changeTenant = function (tenantId)
        {
            var p = $q.defer();

            // set tenant id
            userService.tenantId = tenantId;

            // set tenant in session
            localStorageService.set('CurrentTenant', tenantId);
            
            p.resolve();

            return p.promise;
        };

        userService.getTenantIdentifierById = function (tenantId)
        {
            tenantId = tenantId || -1;
            if (tenantId > 0)
            {
                var selectedTenant = userService.system.tenants
                    .filter(function (o)
                    {
                        return o.id === tenantId;
                    });
                return selectedTenant[0] ? selectedTenant[0].name.toString().toLowerCase() : null;
            } 
            
            return undefined;
        };

        userService.getTenantIdByIdentifier = function (tenantIdentifier)
        {
            if (tenantIdentifier !== undefined)
            {
                var selectedTenant = userService.system.tenants
                    .filter(function (o)
                    {
                        var name = o.name ? o.name.toString().toLowerCase() : '';
                        var identifier = tenantIdentifier ? tenantIdentifier.toString().toLowerCase() : null;
                        return name === identifier;
                    });

                return selectedTenant[0] ? selectedTenant[0].id : null;
            }

            return -1;
        };

        userService.getTenantStatus = function ()
        {
            if (userService.tenantId === -1)
                return userService.tenantId;

            var selectedTenant = userService.system.tenants
                .filter(function (o)
                {
                    return o.id === userService.tenantId;
                });

            return selectedTenant[0] ? selectedTenant[0].statusId : null;
        };

        userService.setTenant = function (tenantIdentifier)
        {
            var p = $q.defer();
            tenantIdentifier = tenantIdentifier || null;
            var tenantId = userService.getTenantIdByIdentifier(tenantIdentifier);

            // reset data on tenant change
            if (tenantId !== userService.tenantId)
            {
                userService.hasModulesLoaded = false;
                userService.isUserLoaded = false;
            }

            // set tenant id
            userService.tenantId = tenantId;

            // set tenant in session
            localStorageService.set('CurrentTenant', tenantId);

            return p.promise;
        };

        userService.currentContract = function () {
            var sContract = localStorageService.get('currentContract');

            return typeof sContract !== 'undefined' ? sContract : '0';
        };

        userService.clearCurrentContract = function () {
            localStorageService.set('currentContract', '0');
        };

        userService.setContract = function (contractId) {
            userService.system.userdata.contractId = contractId * 1;
            localStorageService.set('currentContract', contractId);
            userService.system.context = {...{
                contract: { 
                    id: contractId * 1
                },
            }};
        };

        userService.setContext = function (context) {
            var p = $q.defer();

            if (!context) {
                userService.system.context = undefined;
                p.resolve();
                return p.promise;
            }

            userService.system.context = {...context};
            
            if (context.contract) {
                userService.system.userdata.contractId = context.contract.id;
                userService.system.userdata.contractEntityInstanceId = context.contract.entityInstanceId;
                userService.system.lastContractContext = {...context};

                localStorageService.set('currentContract', context.contract.id);
                localStorageService.set('lastContract', context.contract.id);

                userService.getRolesForContract(context.contract.id);
                userService.isReloadGrid = true;
            }
            else {
                userService.system.userdata.contractEntityInstanceId = undefined;
            }
        
            $http.post(environment.baseApiUrl + '_api/Contracts/AddLastUsedContext', context)
                .then(function () {
                    p.resolve();
                })
                .catch(function (e) { console.error(e); p.reject(); });

            return p.promise;
        };

        userService.changeContextBehavior = function (moduleAbbr) {
            // only Project Files will have a context selection
            // other modules will have a contract selection
            if (moduleAbbr !== 'FCM') {
                userService.system.contextUseOnlyContract = true;
                if (userService.system.lastContractContext) {
                    userService.system.context = {...userService.system.lastContractContext};
                }
                else {
                    userService.ensureContractIsSelected();
                }
            }
            else {
                userService.system.contextUseOnlyContract = false;
                userService.system.context = {...userService.system.context};
            }
        }

        userService.refreshContext = function () {
            userService.system.contextRefresh = true;
        }

        userService.ensureContractIsSelected = function () {
            if (userService.system.contextUseOnlyContract && !userService.system.context?.contract?.id) {
                var contracts = userService.getContextContracts() || [];
                if (contracts.length) {
                    userService.setContext({ contract: contracts[0] });
                }
            }
        }

        userService.setContextTree = function (contextTree) {
            userService.system.contextTree = contextTree || {};
            userService.system.contextTree.loaded = true;
            userService.ensureContractIsSelected();
        }

        userService.getContextContracts = function () {
            if (!userService.system.contextTree || !userService.system.contextTree.loaded) {
                return [];
            }
            var contracts = (userService.system.contextTree.programs || []).reduce((acc, program) => {
                acc.push(
                  ...(program.projects || []).reduce((acc, project) => {
                    acc.push(...(project.contracts || []));
                    return acc;
                  }, []),
                );
                return acc;
              }, []);
            return contracts || [];
        }

        userService.getContextContract = function (contractId, entityInstanceId, contractNo) {
            var contract = (userService.getContextContracts() || []).find(c => 
                (c.id * 1) === (contractId * 1) 
                || (c.entityInstanceId * 1) === (entityInstanceId * 1) 
                || c.no === contractNo);
            return contract;
        }

        userService.isContractClosed = function (contractId, entityInstanceId, contractNo) {
            var contract = userService.getContextContract(contractId, entityInstanceId, contractNo);
            return contract && contract.isClosed === true;
        }

        // triggers
        userService.triggerMenuRefresh = function ()
        {
            return userService.Modules(userService.system.userdata.contractId);
        };

        userService.triggerPermissionsRefresh = function ()
        {
            var p = $q.defer();

            userService.PGrid().then(function (r)
            {
                userService.system.pGrid = r.permissions;
                userService.system.mNames = r.names;
                p.resolve();
            }).catch(function (e)
            {
                p.reject(e);
            });

            return p.promise;
        };

        userService.setRedirect = function (r)
        {
            localStorageService.set('cipoRedirectPage', r);
            return;
        };

        userService.getRedirectPage = function ()
        {
            var r = localStorageService.get('cipoRedirectPage');
            localStorageService.remove('cipoRedirectPage');

            return r;
        };

        return userService;
    }]);
