import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

import { HttpService } from './http.service';
import { FileChunkParams, FileItemModel } from '../../models/module/fields/main';
import { ListResponse } from '../../models/helpers';
import { FILE_SIZES } from '../consts';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  httpService = inject(HttpService);
  httpClient = inject(HttpClient);

  getFileUrl(fileId: number, versionId: number, isPreview?: boolean) {
    const params = this.httpService.buildHttpParams({ fileId, versionId, ...(isPreview ? { isPreview } : {}) });
    return this.httpService.getText('_api/FolderFile/GetFileURL', { httpOptions: { params } });
  }

  downloadFileFromUrl(url: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = 'file';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  getFilesUrl(versionIds: number[]) {
    return this.httpService.put<{ versionId: number; url: string }[]>('_api/FolderFile/GetFilesURL', {
      files: versionIds.map(versionId => ({ versionId })),
    });
  }

  searchFiles(id: number, isCurrent = true) {
    return this.httpService
      .post<ListResponse<FileItemModel>>('_api/FolderFile/Search', { id, isCurrent }, { useLoader: false })
      .pipe(map(response => response.data));
  }

  saveToProjectFiles(file: File) {
    console.log('saveToProjectFiles ' + file.name);
  }

  getChunkData({ file, start, end, chunks }: FileChunkParams) {
    const chunk = file.slice(start, end);
    const formData = new FormData();
    formData.append('file', chunk, file.name);
    formData.append('chunk', Math.ceil(start / FILE_SIZES.chunkSize).toString());
    formData.append('chunks', chunks.toString());
    formData.append('fileName', file.name);
    return formData;
  }
}
