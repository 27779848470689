<div
    class="content"
    [class.cursor--default]="!canOpenOverlay()"
    [class.cursor--pointer]="canOpenOverlay()"
    (click)="toggleContextOverlay()"
    #contextContent>
    @if (context() && !isLoadingTree() && !noData()) {
        @if (!showOnlyLastContextPart() && !useOnlyContract()) {
            @if (context().program) {
                <ng-container *ngTemplateOutlet="contextPart; context: { $implicit: context().program }"></ng-container>
            }
            @if (context().program?.id && context().project?.id) {
                <mat-icon>chevron_right</mat-icon>
            }
            @if (context().project) {
                <ng-container *ngTemplateOutlet="contextPart; context: { $implicit: context().project }"></ng-container>
            }
            @if (context().project?.id && context().contract?.id) {
                <mat-icon>chevron_right</mat-icon>
            }
            @if (context().contract) {
                <ng-container
                    *ngTemplateOutlet="contextPart; context: { $implicit: context().contract }"></ng-container>
            }
        } @else {
            <ng-container *ngTemplateOutlet="contextPart; context: { $implicit: lastContextPart() }"></ng-container>
        }
    } @else if (noData()) {
        <span>{{ "HIERARCHY_CONTEXT.NO_CONTRACTS" | translate }}</span>
    } @else {
        <ngx-skeleton-loader
            class="full-width"
            [theme]="{ height: '12px', margin: 0, display: 'flex' }"></ngx-skeleton-loader>
    }
    @if (canOpenOverlay()) {
        <mat-icon class="arrow-down">arrow_drop_down</mat-icon>
    }
</div>

<ng-template
    cdkConnectedOverlay
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    [cdkConnectedOverlayOrigin]="contextContentOrigin()"
    [cdkConnectedOverlayOpen]="isContextOverlayOpen()"
    [cdkConnectedOverlayHasBackdrop]="true"
    (detach)="isContextOverlayOpen.set(false)"
    (backdropClick)="isContextOverlayOpen.set(false)">
    <div class="overlay-content" [style.width.px]="hostWidth() + 20">
        <div class="context-tree-header">
            <mat-form-field class="cipo-input hide-subscript cipo-input--sm">
                <input
                    matInput
                    type="text"
                    #searchInput
                    [formControl]="searchControl"
                    placeholder="Search..."
                    data-testid="context-view-search" />
                <div matSuffix fxLayout fxLayoutAlign="center center">
                    @if (searchControl.value) {
                        <button mat-icon-button aria-label="Clear" (click)="searchControl.setValue('')">
                            <mat-icon>close</mat-icon>
                        </button>
                    }
                    <mat-checkbox
                        [checked]="showClosed()"
                        [disabled]="isLoadingTree()"
                        data-testid="context-view-closed"
                        (change)="toggleShowClosed()">
                        {{ "HIERARCHY_CONTEXT.CLOSED" | translate }}
                    </mat-checkbox>
                </div>
            </mat-form-field>
        </div>
        <div class="context-tree-container scrollable" #contextScrollContainer>
            @if (isLoadingTree()) {
                <div class="context-tree-skeleton" *ngFor="let i of loadingTreeSkeletonArray">
                    <ngx-skeleton-loader [theme]="{ height: '46px', margin: 0, display: 'flex' }"></ngx-skeleton-loader>
                </div>
            } @else {
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                    <mat-tree-node
                        *matTreeNodeDef="let node"
                        matTreeNodePadding
                        matTreeNodePaddingIndent="12"
                        #contextNodeElement
                        [class.selected]="node.selected"
                        [class.cursor--pointer]="node.context.hasPermission"
                        (click)="nodeClick(node)">
                        <div class="context-node">
                            <ng-container
                                *ngTemplateOutlet="contextPart; context: { $implicit: node.context }"></ng-container>
                        </div>
                    </mat-tree-node>
                    <mat-tree-node
                        *matTreeNodeDef="let node; when: hasChild"
                        matTreeNodePadding
                        matTreeNodePaddingIndent="12"
                        #contextNodeElement
                        [class.sticky-level0]="node.level === 0"
                        [class.sticky-level1]="node.level === 1"
                        [class.selected]="node.selected"
                        [class.cursor--pointer]="node.context.hasPermission"
                        (click)="nodeClick(node)">
                        <div class="context-node">
                            <ng-container
                                *ngTemplateOutlet="contextPart; context: { $implicit: node.context }"></ng-container>
                        </div>
                    </mat-tree-node>
                </mat-tree>
                @if (!dataSource.data.length) {
                    <div class="context-tree-empty">
                        <mat-icon>search</mat-icon>
                        <span>{{ "HIERARCHY_CONTEXT.NO_CONTRACTS_FOUND" | translate }}</span>
                    </div>
                }
            }
        </div>
    </div>
</ng-template>

<ng-template #contextPart let-part>
    @if (part?.id) {
        <itv-module-icon [iconid]="part.moduleIconId" [color]="part.moduleColor"></itv-module-icon>
        @if (part.isClosed) {
            <i class="closedContract">closed</i>
        }
        <span data-testid="context-view-part">
            {{ part.no }}{{ part.no ? " - " : "" }}{{ part.name ?? part.moduleAbbr }}
        </span>
    }
</ng-template>
