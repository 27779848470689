<h2>Button</h2>
<div>
    <section fxLayout
             fxLayoutAlign="start center"
             class="m-y-sm">
        <div fxFlex="10">Basic</div>
        <div fxFlex
             fxLayoutGap="12px">
            <button mat-button>Basic</button>
            <button mat-button
                    class="cipo-text--primary-900">Primary</button>
            <button mat-button
                    class="cipo-text--accent-900">Accent</button>
            <button mat-button
                    class="cipo-text--warn-900">Warn</button>
            <button mat-button
                    disabled>Disabled</button>
            <a mat-button
               href="https://www.google.com/"
               target="_blank">Link</a>
        </div>
    </section>
    <mat-divider></mat-divider>
    <section fxLayout
             fxLayoutAlign="start center"
             class="m-y-sm">
        <div fxFlex="10">Raised</div>
        <div fxFlex
             fxLayoutGap="12px">
            <button mat-raised-button>Basic</button>
            <button mat-raised-button
                    class="cipo-text--white cipo-bg--primary-900">Primary</button>
            <button mat-raised-button
                    class="cipo-text--white cipo-bg--accent-900">Accent</button>
            <button mat-raised-button
                    class="cipo-text--white cipo-bg--warn-900">Warn</button>
            <button mat-raised-button
                    disabled>Disabled</button>
            <a mat-raised-button
               href="https://www.google.com/"
               target="_blank">Link</a>
        </div>
    </section>
    <mat-divider></mat-divider>
    <section fxLayout
             fxLayoutAlign="start center"
             class="m-y-sm">
        <div fxFlex="10">Stroked</div>
        <div fxFlex
             fxLayoutGap="12px">
            <button mat-stroked-button>Basic</button>
            <button mat-stroked-button
                    class="cipo-text--primary-900">Primary</button>
            <button mat-stroked-button
                    class="cipo-text--accent-900">Accent</button>
            <button mat-stroked-button
                    class="cipo-text--warn-900">Warn</button>
            <button mat-stroked-button
                    disabled>Disabled</button>
            <a mat-stroked-button
               href="https://www.google.com/"
               target="_blank">Link</a>
        </div>
    </section>
    <mat-divider></mat-divider>
    <section fxLayout
             fxLayoutAlign="start center"
             class="m-y-sm">
        <div fxFlex="10">Flat</div>
        <div fxFlex
             fxLayoutGap="12px">
            <button mat-flat-button>Basic</button>
            <button mat-flat-button
                    class="cipo-text--white cipo-bg--primary-900">Primary</button>
            <button mat-flat-button
                    class="cipo-text--white cipo-bg--accent-900">Accent</button>
            <button mat-flat-button
                    class="cipo-text--white cipo-bg--warn-900">Warn</button>
            <button mat-flat-button
                    disabled>Disabled</button>
            <a mat-flat-button
               href="https://www.google.com/"
               target="_blank">Link</a>
        </div>
    </section>
    <mat-divider></mat-divider>
    <section fxLayout
             fxLayoutAlign="start center"
             class="m-y-sm">
        <div fxFlex="10">Icon</div>
        <div fxFlex
             fxLayoutGap="12px">
            <button mat-icon-button
                    aria-label="Example icon button with a vertical three dot icon">
                <mat-icon>more_vert</mat-icon>
            </button>
            <button mat-icon-button
                    class="cipo-text--primary-900"
                    aria-label="Example icon button with a home icon">
                <mat-icon>home</mat-icon>
            </button>
            <button mat-icon-button
                    class="cipo-text--accent-900"
                    aria-label="Example icon button with a menu icon">
                <mat-icon>menu</mat-icon>
            </button>
            <button mat-icon-button
                    class="cipo-text--warn-900"
                    aria-label="Example icon button with a heart icon">
                <mat-icon>favorite</mat-icon>
            </button>
            <button mat-icon-button
                    disabled
                    aria-label="Example icon button with a open in new tab icon">
                <mat-icon>open_in_new</mat-icon>
            </button>
        </div>
    </section>
    <mat-divider></mat-divider>
    <section fxLayout
             fxLayoutAlign="start center"
             class="m-y-sm">
        <div fxFlex="10">FAB</div>
        <div fxLayout
             fxFlex
             fxLayoutGap="12px">
            <button mat-fab
                    class="cipo-text--white cipo-bg--primary-900"
                    aria-label="Example icon button with a delete icon">
                <mat-icon>delete</mat-icon>
            </button>

            <button mat-fab
                    class="cipo-text--white cipo-bg--accent-900"
                    aria-label="Example icon button with a bookmark icon">
                <mat-icon>bookmark</mat-icon>
            </button>

            <button mat-fab
                    class="cipo-text--white cipo-bg--warn-900"
                    aria-label="Example icon button with a home icon">
                <mat-icon>home</mat-icon>
            </button>

            <button mat-fab
                    disabled
                    aria-label="Example icon button with a heart icon">
                <mat-icon>favorite</mat-icon>
            </button>
        </div>
    </section>
    <mat-divider></mat-divider>
    <section fxLayout
             fxLayoutAlign="start center"
             class="m-y-sm">
        <div fxFlex="10">Mini FAB</div>
        <div fxLayout
             fxFlex
             fxLayoutGap="12px">
            <button mat-mini-fab
                    class="cipo-text--white cipo-bg--primary-900"
                    aria-label="Example icon button with a menu icon">
                <mat-icon>menu</mat-icon>
            </button>

            <button mat-mini-fab
                    class="cipo-text--white cipo-bg--accent-900"
                    aria-label="Example icon button with a plus one icon">
                <mat-icon>plus_one</mat-icon>
            </button>

            <button mat-mini-fab
                    class="cipo-text--white cipo-bg--warn-900"
                    aria-label="Example icon button with a filter list icon">
                <mat-icon>filter_list</mat-icon>
            </button>

            <button mat-mini-fab
                    disabled
                    aria-label="Example icon button with a home icon">
                <mat-icon>home</mat-icon>
            </button>
        </div>
    </section>
</div>

<h2>Button toggle</h2>
<div fxLayout="column"
     fxLayoutGap="12px">
    <div fxLayout
         fxLayoutAlign="start center">
        <p fxFlex="15">Default appearance:</p>
        <mat-button-toggle-group name="fontStyle"
                                 aria-label="Font Style">
            <mat-button-toggle value="bold">Bold</mat-button-toggle>
            <mat-button-toggle value="italic">Italic</mat-button-toggle>
            <mat-button-toggle value="underline">Underline</mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <div fxLayout
         fxLayoutAlign="start center">
        <p fxFlex="15">Icons:</p>
        <mat-button-toggle-group #group="matButtonToggleGroup">
            <mat-button-toggle value="left"
                               aria-label="Text align left">
                <mat-icon>format_align_left</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="center"
                               aria-label="Text align center">
                <mat-icon>format_align_center</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="right"
                               aria-label="Text align right">
                <mat-icon>format_align_right</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="justify"
                               disabled
                               aria-label="Text align justify">
                <mat-icon>format_align_justify</mat-icon>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <div fxLayout
         fxLayoutAlign="start center">
        <p fxFlex="15">Multiple selection:</p>
        <mat-button-toggle-group name="ingredients"
                                 aria-label="Ingredients"
                                 multiple>
            <mat-button-toggle value="flour">Flour</mat-button-toggle>
            <mat-button-toggle value="eggs">Eggs</mat-button-toggle>
            <mat-button-toggle value="sugar">Sugar</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>